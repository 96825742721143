import { NgClass, NgFor, NgForOf, NgIf, NgStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DialogService } from 'primeng/dynamicdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';

import { ShellService } from '../../../shared/services/shell/shell.service';
import { VesselViewModel } from '../models/vessel.model';
import { VesselService } from '../services/vessel/vessel.service';
import { VesselDialogViewModel } from '../viewModels/vesselDialog.model';
import { AccessLevel } from '../../../auth/app-roles';




@Component({
  selector: 'app-vessels',
  standalone: true,
  imports: [FormsModule, TableModule, DialogModule, ConfirmDialogModule,InputSwitchModule, NgIf, NgStyle, NgClass, NgForOf, NgFor],
  providers: [DialogService, ConfirmationService],
  templateUrl: './vessels.component.html',
  styleUrl: './vessels.component.scss'
})
export class VesselsComponent implements OnInit {
  accessLevel: AccessLevel = AccessLevel.NoAccess;
  loading = false;
  vessels: VesselViewModel[] = [];
  cols: any;
  first: number = 0;
  isFilterModeOn = false;

  vesselFilterName: string = "";
  vesselFilterDescription: string = "";
  isActive = true;

  vesselDialogModel: VesselDialogViewModel = new VesselDialogViewModel();

  constructor(private vesselService: VesselService, private shellService: ShellService,
    private dialogService: DialogService, private confirmationService: ConfirmationService) { }

  ngOnInit(): void {
    this.loadVesselGridData()
    this.accessLevel = this.shellService.getAppAccessLevel();
  }

  //initialize header
  headerInitialize() {
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'vesselDescription', header: 'Description' }];
  }

  resetFilterControls() {
    this.vesselFilterName = "";
    this.vesselFilterDescription = "";
    this.isActive = true;
    this.loadVesselGridData();
  }

  showFilter() {
    this.isFilterModeOn = true;
  }

  closeFilter() {
    this.isFilterModeOn = false;
  }

  //#region grid data binding API call
  loadVesselGridData() {
    this.loading = true;
    this.vessels = [];
    var filterData = {
      vesselName: this.vesselFilterName,
      description: this.vesselFilterDescription,
      active: this.isActive
    }
    this.vesselService.searchVessels(filterData).subscribe(response => {
      if (response) {
        this.first = 0;
        this.vessels = response;
      }
      this.headerInitialize();
      this.loading = false;
    })
  }

  // Vessel Dialog
  openVesselDialog(rowData: any = null): void {
    this.resetVesselDialog();
    if (rowData != null) {
      this.vesselDialogModel.id = rowData.id;
      this.vesselDialogModel.vesselName = rowData.name;
      this.vesselDialogModel.vesselDescription = rowData.vesselDescription;
      this.vesselDialogModel.isUpdate = true;
      this.vesselDialogModel.originalData = JSON.parse(JSON.stringify(this.vesselDialogModel));
    } else {
      this.vesselDialogModel.isUpdate = false;
    }

    this.vesselDialogModel.isOpen = true;
  }

  resetVesselDialog(): void {
    this.vesselDialogModel.vesselName = "";
    this.vesselDialogModel.vesselDescription = "";
    this.vesselDialogModel.isVesselNameEmpty = false;
  }

  addVessel(): void {
    if (this.vesselDialogModel.vesselName == "") {
      this.vesselDialogModel.isVesselNameEmpty = true;
      return;
    }

    var vesselObj = {
      name: this.vesselDialogModel.vesselName,
      vesselDescription: this.vesselDialogModel.vesselDescription,
      createdDate: new Date()
    }
    this.shellService.showLoadingIndicator(true);
    this.vesselService.addVessel(vesselObj).subscribe((response) => {
      this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Vessel added successfully', severity: 'success' })
      this.shellService.showLoadingIndicator(false);
      this.vesselDialogModel.isOpen = false;
      this.loadVesselGridData();
    }, (error) => {
      this.shellService.showLoadingIndicator(false);
      this.vesselDialogModel.isOpen = false;
    })
  }

  updateVessel() {
    if (this.vesselDialogModel.vesselName == "") {
      this.vesselDialogModel.isVesselNameEmpty = true;
      return;
    }
    var batchPatchModel = this.bindVesselPatchArrayObj();
    if (batchPatchModel.length > 0) {
      this.shellService.showLoadingIndicator(true);
      this.vesselService.patchUpdate(this.vesselDialogModel.originalData.id, batchPatchModel).subscribe(response => {
        this.vesselDialogModel.isOpen = false;
        this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Vessel updated successfully!', severity: 'success' })
        this.loadVesselGridData();
        this.shellService.showLoadingIndicator(false);
      }, error => {
        this.vesselDialogModel.isOpen = false;
        this.shellService.showLoadingIndicator(false);
      })
    }
  }

  tempPatchArrayObj: any = []
  bindVesselPatchArrayObj() {
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (this.vesselDialogModel.originalData.vesselName != this.vesselDialogModel.vesselName) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("name", this.vesselDialogModel.vesselName));
    }

    if (this.vesselDialogModel.originalData.vesselDescription != this.vesselDialogModel.vesselDescription) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("vesselDescription", this.vesselDialogModel.vesselDescription));
    }

    if (this.tempPatchArrayObj.length > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()));
      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  deleteVessel(rowData: any): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this vessel?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'aq-destructive ',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-outline-primary ',
      accept: () => {
        var patchArray = [
          {
            "value": false,
            "path": "/active",
            "op": "replace"
          },
          {
            "value": new Date(),
            "path": "/modifiedDate",
            "op": "replace"
          }
        ]
        this.shellService.showLoadingIndicator(true);
        this.vesselService.patchUpdate(rowData.id, patchArray).subscribe((response) => {
          this.shellService.showLoadingIndicator(false);
          this.loadVesselGridData();
          this.shellService.alert(this.dialogService, { title: 'Deleted!', message: 'Vessel deleted successfully', severity: 'success' })
        }, (error) => {
          this.shellService.showLoadingIndicator(false);
        })
      }
    });
  }

  reactivateVessel(obj: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reactivate this vessel?',
      acceptLabel: 'Activate',
      acceptIcon: 'pi pi-chevron-circle-up',
      acceptButtonStyleClass: 'aq-success ',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        var patchArray = [
          {
            "value": true,
            "path": "/active",
            "op": "replace"
          },
          {
            "value": new Date(),
            "path": "/modifiedDate",
            "op": "replace"
          }
        ]
        this.shellService.showLoadingIndicator(true);
        this.vesselService.patchUpdate(obj.id, patchArray).subscribe((response) => {
          this.shellService.showLoadingIndicator(false);
          this.loadVesselGridData();
          this.shellService.alert(this.dialogService, { title: 'Activated', message: 'Vessel reactivated successfully', severity: 'success' })
        }, (error) => {
          this.shellService.showLoadingIndicator(false);
        })
      }
    });
  }

}
