import { DecimalPipe, NgClass, NgFor, NgIf, NgStyle } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { DialogService } from "primeng/dynamicdialog";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { TableModule } from "primeng/table";
import { TooltipModule } from "primeng/tooltip";
import { forkJoin } from "rxjs";
import { AccessLevel } from "../../../auth/app-roles";
import { constProduct } from "../../../shared/constants";
import { ShellService } from "../../../shared/services/shell/shell.service";
import { AnchorRefractoryViewModel, VesselProductViewModel } from "../models/nominalLining.model";
import { Product } from "../models/product.model";
import { Category } from "../models/productCategory.model";
import { ProductType } from "../models/productType.model";
import { Spacing } from "../models/spacing.model";
import { Vessel } from "../models/vessel.model";
import { VesselEstimateRevision } from "../models/vesselEstimateRevision.model";
import { Zone } from "../models/zone.model";
import { AppSettingService } from "../services/app-setting/app-setting.service";
import { NominalLiningService } from "../services/nominal-lining/nominal-lining.service";
import { OutageService } from "../services/outage/outage.service";
import { ProductService } from "../services/product/product.service";
import { RefractoryEstimationService } from "../services/refractory-estimation/refractory-estimation.service";
import { SiteService } from "../services/site/site.service";
import { UnitService } from "../services/unit/unit.service";
import { VesselProductService } from "../services/vessel-product/vessel-product.service";


@Component({
  selector: 'app-estimate-refractory-materials',
  standalone: true,
  imports: [NgIf, NgClass, NgFor, NgStyle, DecimalPipe, DropdownModule, FormsModule, ButtonModule, TooltipModule, ConfirmDialogModule, InputSwitchModule, InputNumberModule, TableModule, DialogModule, OverlayPanelModule],
  providers: [DialogService, ConfirmationService],
  templateUrl: './estimate-refractory-materials.component.html',
  styleUrl: './estimate-refractory-materials.component.scss'
})
export class EstimateRefractoryMaterialsComponent implements OnInit {
  accessLevel: AccessLevel = AccessLevel.NoAccess;
  showingVesselProductGrid = false;
  addVesselProductNewRecordMode: boolean = false;

  // Dropdown data
  revisions: VesselEstimateRevision[] = []
  vessels: Vessel[] = []
  zones: Zone[] = []
  siteUnitOutageObj: any

  // Dropdown selections
  selectedRevision?: VesselEstimateRevision
  selectedVessel?: Vessel
  selectedZone?: Zone

  //Dropdown validations
  isRevisionEmpty = false
  isVesselNameEmpty = false
  isZoneNameEmpty = false

  // Fields
  revisionComment: string = ""
  originalRevisionComment: string = ""
  hf_gcf = 0.0
  bk_gcf = 0.0

  // Estimated repair area
  vesselEstimateId: number = 0;
  repairArea: number = 0.0
  hotfaceRepairArea: number = 0.0
  isEstimateRepairAreaEmpty: boolean = false;

  // Anchor details
  loading: boolean = false
  anchorBackupCols: any[] = []
  anchorHotfaceCols: any[] = []
  anchorBackupGridData: any
  anchorHotfaceGridData: any

  // Refractory details
  refractoryBackupCols: any[] = []
  refractoryHotfaceCols: any[] = []
  refractoryBackupGridData: any
  refractoryHotfaceGridData: any

  // Costing
  totalKgPerZone: number = 0
  totalQtyPerZone: number = 0
  estimatedLabourCostByWeight: number = 0
  estimatedLabourCostByQty: number = 0
  estimateRefractoryCostPerKg: number = 0
  estimateAnchorCostPerQty: number = 0;
  tempRepairArea: number = 0;
  tempEstimateAnchorCostPerQty: number = 0;
  tempEstimateRefractoryCostPerKg: number = 0;
  totalAnchorCost: number = 0
  totalRefractoryCost: number = 0
  totalLabourAndEqpCost: number = 0
  totalCost: number = 0

  // Nominal Lining Data
  id: number = 0
  gridRowData: any = []
  DialogProductType: string = ""
  DialogProductPosition: string = ""

  products: Product[] = []
  refractoryProducts: Product[] = [];
  selectedProduct: Product | any
  isProductDataLoaded: boolean = false
  spacings: Spacing[] = []
  selectedSpacing: Spacing | any
  isSpacingDataLoaded: boolean = false

  isAnchorDensityThicknessCostPerTonneConversionDataLoaded: boolean = false
  isRefractoryProductDataLoaded: boolean = false

  anchorRefractoryObj: AnchorRefractoryViewModel = new AnchorRefractoryViewModel()
  anchorDensity: number = 0
  thicknessConversion: number = 0
  costPerTonne: number = 0
  costPerUnit: number = 0;

  first: number = 0;

  estimateRefractoryProductDialog = {
    opened: false,
    isProductEmpty: false,
    isAreaEmpty: false,
    isAreaGreaterThanRepairArea: "",
    isQtyEmpty: false,
    isSpacingEmpty: false,
    isProductCostEmpty: false,
    isUpdate: false
  }
  // Rahul's Addition
  zoneDetails: any = []
  disableStatus = false

  constructor(private outageService: OutageService,
    private productService: ProductService,
    private refractoryEstimationService: RefractoryEstimationService,
    private nominalService: NominalLiningService,
    private shellService: ShellService,
    // private alertService: AlertModalService,
    private confirmationService: ConfirmationService,
    private appSettings: AppSettingService,
    private unitService: UnitService,
    private siteService: SiteService,
    private vesselProductService: VesselProductService,
    private dialogService: DialogService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.vesselProductObj = new VesselProductViewModel();
    this.shellService.showLoadingIndicator(true);
    // this.shellService.getAccessLevel().then((accessLevel) => {
    //   this.accessLevel = accessLevel;
    //   this.anchorBackupGridHeader();
    //   this.anchorHotfaceGridHeader();
    // })
    this.accessLevel = this.shellService.getAppAccessLevel();
    this.anchorBackupGridHeader();
    this.anchorHotfaceGridHeader();

    this.siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
    if (this.siteUnitOutageObj.unitId > 0 && this.siteUnitOutageObj.outageId > 0) {
      var checkOutageName = this.siteUnitOutageObj.outageName.split('-');
      var unitName = checkOutageName[0].trim();
      var unitoutageNameType = checkOutageName[1].trim();
      var globalUnitName = this.siteUnitOutageObj.unitName;
      if (unitName == globalUnitName && unitoutageNameType == 'Nominal Lining') {
        this.shellService.showLoadingIndicator(false);
        this.shellService.resetConfig(true);
      } else {
        this.getRevisions(this.siteUnitOutageObj.outageId);
      }
      this.getSiteDetail(this.siteUnitOutageObj.siteId);
      if (this.isAnchorDensityThicknessCostPerTonneConversionDataLoaded == false) {
        this.getAnchorDensityThicknessCostPerTonneConversion(this.siteUnitOutageObj.countryId, 2); ////2 is enum code 
      }
    } else {
      this.shellService.showLoadingIndicator(false);
      this.shellService.resetConfig(true);
    }

    this.shellService.globalSiteUnitOutageSet.subscribe((response) => {
      this.getRevisions(response.outageId);
      this.resetEstimateRefractoryData();
      this.getSiteDetail(response.siteId);
      if (this.isAnchorDensityThicknessCostPerTonneConversionDataLoaded == false) {
        this.getAnchorDensityThicknessCostPerTonneConversion(this.siteUnitOutageObj.countryId, 2); ////2 is enum code 
      }
    });


    this.anchorRefractoryObj = new AnchorRefractoryViewModel();
    this.refractoryBackupGridHeader();
    this.refractoryHotfaceGridHeader();

  }

  disableAddNewRecord: boolean = false;
  // Dropdowns
  getRevisions(outageId: number): void {
    this.outageService.getRevisions(outageId).subscribe((response) => {
      if (response != null) {
        // Remove before migrating to Prod
        console.log("revision ", response)
        this.revisions = response;
        if (response.length > 0) {
          this.disableAddNewRecord = false;
          this.selectedRevision = this.revisions[response.length - 1];
          this.getVessels(this.siteUnitOutageObj.unitId, this.selectedRevision.id);
          this.revisionComment = this.selectedRevision.revisionComment;
          this.originalRevisionComment = this.selectedRevision.revisionComment;
        } else {
          this.disableAddNewRecord = true;
        }
      } else if (this.addNewRecordMode == true) {
        this.getVessels(this.siteUnitOutageObj.unitId, 0); //gets vessel based on unit id if there is no revision present and add new record mode is on
      }
      else {
        this.getVessels(this.siteUnitOutageObj.unitId, 0); //gets vessel based on unit id if there is no revision present
        this.disableAddNewRecord = true;
      }
      this.shellService.showLoadingIndicator(false);
    });
  }

  onRevisionChange(event: any): void {
    if (this.showingVesselProductGrid) {
      this.addNewVesselProductRecordToggle();
    } else {
      if (this.selectedRevision != undefined) {
        this.getVessels(this.siteUnitOutageObj.unitId, this.selectedRevision.id);
        this.revisionComment = this.selectedRevision.revisionComment;
        this.originalRevisionComment = this.selectedRevision.revisionComment;
        this.resetEstimateRefractoryData();
        this.zones = [];
      }
    }

  }

  gangRate: number = 0;
  getSiteDetail(siteId: number) {
    this.gangRate = 0;
    this.siteService.getSiteById(siteId).subscribe((data) => {
      this.gangRate = data.gangRate;
    });
  }

  getZones(vesselId: number): void {
    this.zones = [];
    this.zoneDetails = [];
    this.selectedZone = undefined;
    this.isZoneNameEmpty = true;
    var revisionId = this.selectedRevision ? this.selectedRevision.id : 0;
    this.refractoryEstimationService.getZoneBasedOnVesselEstimate(this.siteUnitOutageObj.unitId, revisionId, vesselId, this.addNewRecordMode).subscribe(response => {
      for (let i = 0; i < response[0].length; i++) {
        this.zones.push(response[0][i]['zone'])
        this.zoneDetails.push({ 'isLeafNode': response[0][i]['isLeafNode'], 'id': response[0][i]['zoneId'] })
      }
      this.cdRef.detectChanges();
    })
  }

  onZoneChange(event: any): void {
    if (this.selectedVessel) {
      if (this.addNewRecordMode == true) { //will be true only if add new button is clicked
        this.confirmationService.confirm({
          message: 'Base the new record on the Nominal Lining Design?',
          acceptLabel: 'Yes',
          acceptIcon: 'pi pi-check',
          acceptButtonStyleClass: 'aq-primary ',
          rejectLabel: 'No',
          rejectIcon: 'pi pi-times',
          rejectButtonStyleClass: 'aq-primary aq-outline-primary ',
          accept: () => {
            this.copyNominalIntoEstimateRefractory();
          },
          reject: () => {
            this.loadAnchorRefractoryProductsData();
          }
        });
      }
      else {
        this.isZoneNameEmpty = false;
        this.loadProductDropdownData('Anchor');
        this.loadAnchorRefractoryProductsData();
      }
    }
  }

  getVessels(siteUnitId: number, revisionId: number): void {
    this.selectedVessel = undefined;
    this.selectedZone = undefined;
    this.vessels = [];
    this.zones = [];
    this.isVesselNameEmpty = true;
    this.refractoryEstimationService.getVesselBasedOnVesselEstimate(siteUnitId, revisionId).subscribe((response) => {
      if (response != null) {
        this.vessels = response;
        // console.log(response)
      } else {
        this.vessels = [];
      }
    });
  }

  onVesselChange(event: any): void {
    if (this.showingVesselProductGrid == true) {
      if (this.selectedRevision) {
        if (this.addVesselProductNewRecordMode == true) { //will be true only if add new button is clicked
          this.confirmationService.confirm({
            message: 'Base the new record on the Vessel Product Nominal Lining?',
            acceptLabel: 'Yes',
            acceptIcon: 'pi pi-check',
            acceptButtonStyleClass: 'aq-primary ',
            rejectLabel: 'No',
            rejectIcon: 'pi pi-times',
            rejectButtonStyleClass: 'aq-primary p-button-outlined ',
            accept: () => {
              this.copyNominalVesselProduct();
            },
            reject: () => {
              this.loadVesselProductGridData();
            }
          });
        }
        else {
          this.isZoneNameEmpty = false;
          this.loadVesselProductGridData();
        }
      }
    } else {
      if (event) {
        this.resetEstimateRefractoryData();
        this.isVesselNameEmpty = false;
        this.getZones(event.value.id);
      }
    }

  }

  // Estimated repair area
  incrementDecimalRepairArea(): void {
    if (this.repairArea)
      this.repairArea += 0.01;
    else
      this.repairArea = 0.01;
  }

  decrementDecimalRepairArea(): void {
    if (this.repairArea > 0.01)
      this.repairArea -= 0.01;
    else
      this.repairArea = 0;
  }

  // Filter
  validateFilterSelection(isVesselProduct?: boolean): boolean {
    var isValid = true;

    if (this.selectedRevision) {
      this.isRevisionEmpty = false;
    } else {
      this.isRevisionEmpty = true;
      isValid = false;
    }
    var vesslName = this.selectedVessel ? this.selectedVessel.name : "";
    if (vesslName) {
      this.isVesselNameEmpty = false;
    } else {
      this.isVesselNameEmpty = true;
      isValid = false;
    }
    if (isVesselProduct == false) {
      var zone = this.selectedZone ? this.selectedZone.name : "";
      if (zone) {
        this.isZoneNameEmpty = false;
      } else {
        this.isZoneNameEmpty = true;
        isValid = false;
      }
    }

    return isValid;
  }

  createSearchObj(productType: string, productPosition: string) {
    var searchObj: ErmSearchParameters = {
      unitId: this.siteUnitOutageObj.unitId,
      outageId: this.siteUnitOutageObj.outageId,
      vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
      zoneId: this.selectedZone ? this.selectedZone.id : 0,
      productType: productType,
      productPosition: productPosition,
      revisionId: this.selectedRevision != null ? this.selectedRevision.id : 0,
      active: true
    }
    return searchObj;
  }

  // Anchor details
  editRow(data: any, type: string): void {
    this.estimateRefractoryProductDialog.isUpdate = true;
    this.id = data.id;
    var productType = "";
    var productPosition = "";
    if (type == 'anchorBackup') {
      productType = constProduct.anchorType;
      productPosition = constProduct.positionBackup;
    }
    else if (type == 'anchorHotface') {
      productType = constProduct.anchorType;
      productPosition = constProduct.positionHotface;
    }
    else if (type == 'refractoryBackup') {
      productType = constProduct.refractoryType;
      productPosition = constProduct.positionBackup;
    }
    else if (type == 'refractoryHotface') {
      productType = constProduct.refractoryType;
      productPosition = constProduct.positionHotface;
    }
    var spacing = "";
    if (productType == 'Anchor') {
      this.anchorRefractoryObj.thickness = data.thickness;
      spacing = data.anchorSpacing;
    }
    if (productType == 'Refractory') {
      this.anchorRefractoryObj.thickness = data.thickness;
      this.anchorRefractoryObj.usageFactor = data.usageFactor;
      this.anchorRefractoryObj.volume = data.volume;
      this.anchorRefractoryObj.baseWeight = data.baseWeight;
    }
    this.anchorRefractoryObj.active = data.active;
    this.anchorRefractoryObj.area = data.area;
    this.anchorRefractoryObj.qty = data.qty;
    this.anchorRefractoryObj.productCost = data.productCost;
    this.toggleEstimateRefractoryProductDialog(productType, productPosition, data.product, spacing);
    this.setRowData(data.product, spacing);
  }

  deleteObj: any;
  deleteRow(obj: any, productType: string, productPosition: string): void {
    this.deleteObj = {};
    this.deleteObj = {
      estimateRefractoryId: obj.id,
      productType: productType,
      productPosition: productPosition
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this product?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'aq-destructive ',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined ',
      accept: () => {
        this.deleteConfirm();
      },
      reject: () => {
        this.deleteObj = {};
      }
    });
  }

  bulkDelete() {
    if (!this.validateFilterSelection()) {
      return;
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the repair area, all the anchor and refractory backup and hotface for this vessel and zone?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'aq-destructive ',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        this.bulkDeleteConfirm();
      }
    });
  }

  bulkDeleteConfirm() {
    this.refractoryEstimationService.bulkDeleteEstimateRefractoryDetails(
      this.shellService.loadGlobalSiteUnitOutage().unitId,
      this.selectedVessel ? this.selectedVessel.id : 0,
      this.selectedZone ? this.selectedZone.id : 0,
      this.shellService.loadGlobalSiteUnitOutage().outageId,
      this.selectedRevision ? this.selectedRevision.id : 0
    ).subscribe({next:() => {
      // this.alertService.openAlert("Deleted!", "Repair area and related records deleted successfully", "success");
      this.shellService.alert(this.dialogService, { title: "Deleted!", message: "Repair area and related records deleted successfully", severity: 'success' })
      this.shellService.showLoadingIndicator(false);
      this.loadAnchorRefractoryProductsData();
      var revisionId = this.selectedRevision ? this.selectedRevision.id : 0;
      this.getVessels(this.shellService.loadGlobalSiteUnitOutage().unitId, revisionId);
    }, error:() => {
      // this.alertService.openAlert("Operation Failed", "Could not delete the records", "error");
      this.shellService.alert(this.dialogService, { title: 'Operation Failed', message: "Could not delete the record", severity: 'error' })
      this.shellService.showLoadingIndicator(false);
    }});
  }

  // Add revision data
  addNewRevision(revisionType: number): void {
    this.confirmationService.confirm({
      message: `Do you want to create the revision based on revision ${this.selectedRevision?.revisionNo} ?`,
      acceptLabel: 'Yes',
      acceptIcon: 'pi pi-check',
      acceptButtonStyleClass: 'aq-primary',
      rejectLabel: ' No',
      rejectIcon: 'pi pi-times',
      rejectButtonStyleClass: 'aq-outline-primary ', accept: () => {
        this.shellService.showLoadingIndicator(true);
        var revisionObject = {
          siteUnitId: this.shellService.loadGlobalSiteUnitOutage().unitId,
          outageId: this.shellService.loadGlobalSiteUnitOutage().outageId,
          revisionCommentType: revisionType == 1 ? "Scope-Inspection" : "Budget Revision",
        }
               this.refractoryEstimationService.addRevisionFromTempate(revisionObject,1,this.selectedRevision!.revisionNo).subscribe({
          next: () => {
            this.shellService.alert(this.dialogService, { title: "Success!", message: `New Revision Added based on ${this.selectedRevision?.revisionNo} . Please wait...`, severity: 'success' })
            this.shellService.showLoadingIndicator(false);
            setTimeout(() => { window.location.reload(); }, 4000)
          }, error: () => {
            this.shellService.alert(this.dialogService, { title: "Operation Failed", message: "Failed to add new revision", severity: 'error' })
            this.shellService.showLoadingIndicator(false);
          }
        })
      }, reject: () => {
        this.shellService.alert(this.dialogService, { title: 'Info!', message: "Please choose your preferred revision to choose as a template", severity: 'info' });
        this.shellService.showLoadingIndicator(false);
      }
    })

    // this.refractoryEstimationService.addRevision(revisionObject)
    //   .subscribe(() => {
    //     // this.alertService.openAlert("Success!", "New Revision Added. Please wait...", "success");
    //     this.shellService.alert(this.dialogService,{title:"Success!",message:"New Revision Added. Please wait...",severity:'success'})
    //     this.shellService.showLoadingIndicator(false);
    //     setTimeout(() => { window.location.reload(); }, 4000)
    //   }, error => {
    //     // this.alertService.openAlert("Operation Failed", "Failed to add new revision", "error");
    //     this.shellService.alert(this.dialogService,{title:"Operation Failed",message:"Failed to add new revision",severity:'error'})
    //     this.shellService.showLoadingIndicator(false);
    //   })
  }

  addNewRecordMode: boolean = false;
  // Add new record
  addRecord(): void {
    if (this.addNewRecordMode == true) {
      var siteUnitId = this.shellService.loadGlobalSiteUnitOutage().unitId;
      this.getVessels(siteUnitId, 0); //revision id passed as 0 so it can fetch all vessel for this unit
    } else {
      var siteUnitId = this.shellService.loadGlobalSiteUnitOutage().unitId;
      var revisionId = this.selectedRevision ? this.selectedRevision.id : 0;
      this.getVessels(siteUnitId, revisionId);
    }
    this.resetEstimateRefractoryData();
  }

  copyNominalIntoEstimateRefractory() {
    var copyNominalObj = {
      revisionId: this.selectedRevision ? this.selectedRevision.id : 0,
      unitId: this.shellService.loadGlobalSiteUnitOutage().unitId,
      outageId: this.shellService.loadGlobalSiteUnitOutage().outageId,
      vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
      zoneId: this.selectedZone ? this.selectedZone.id : 0,
    }
    this.nominalService.copyNominalIntoEstimateRefractory(copyNominalObj)
      .subscribe({
        next: () => {
          // this.alertService.openAlert("Success!", "Created record based on nominal lining", "success");
          this.shellService.alert(this.dialogService, { title: "Success!", message: "Created record based on nominal lining", severity: "success" })
          this.shellService.showLoadingIndicator(false);
          this.addNewRecordMode = false;
          this.loadAnchorRefractoryProductsData(true);
          this.refractoryEstimationService.getZoneBasedOnVesselEstimate(copyNominalObj.unitId, copyNominalObj.revisionId, copyNominalObj.vesselId, false).subscribe(response => {
            this.zones = response;
          })
        }, error: () => {
          // this.alertService.openAlert("Operation Failed", error, "error");
          this.shellService.alert(this.dialogService, { title: 'Operation Failed', message: 'error', severity: 'error' })
          this.shellService.showLoadingIndicator(false);
        }
      });
  }

  // //Sets the original grid data
  setRowData(productName: string, spacing?: string) {
    this.gridRowData = {
      id: this.id,
      productName: productName,
      thickness: this.anchorRefractoryObj.thickness,
      anchorSpacing: spacing ? spacing : "",
      area: this.anchorRefractoryObj.area,
      qty: this.anchorRefractoryObj.qty,
      productCost: this.anchorRefractoryObj.productCost,
      usageFactor: this.anchorRefractoryObj.usageFactor,
      volume: this.anchorRefractoryObj.volume,
      baseWeight: this.anchorRefractoryObj.baseWeight,
      active: this.anchorRefractoryObj.active
    }
  }

  // Grid data
  //#region Anchor details backup grid
  anchorBackupGridHeader() {
    this.anchorBackupCols = [
      { field: 'product', header: 'Backup Anchors', width: this.accessLevel > AccessLevel.Contractor ? '25%' : '30%' },
      { field: 'area', header: 'Area (SqM)', width: this.accessLevel > AccessLevel.Contractor ? '18%' : '20%', format: true },
      { field: 'anchorSpacing', header: 'Spacing', width: this.accessLevel > AccessLevel.Contractor ? '15%' : '20%' },
      { field: 'qty', header: 'Qty', width: this.accessLevel > AccessLevel.Contractor ? '8%' : '10%' },
      { field: 'productCost', header: 'Cost($)', width: this.accessLevel > AccessLevel.Contractor ? '10%' : '20%', format: true }];
  }

  //#region Anchor details hotface grid
  anchorHotfaceGridHeader() {
    this.anchorHotfaceCols = [
      { field: 'product', header: 'Hotface Anchors', width: this.accessLevel > AccessLevel.Contractor ? '25%' : '30%' },
      { field: 'area', header: 'Area (SqM)', width: this.accessLevel > AccessLevel.Contractor ? '18%' : '20%', format: true },
      { field: 'anchorSpacing', header: 'Spacing', width: this.accessLevel > AccessLevel.Contractor ? '15%' : '20%' },
      { field: 'qty', header: 'Qty', width: this.accessLevel > AccessLevel.Contractor ? '8%' : '10%' },
      { field: 'productCost', header: 'Cost($)', width: this.accessLevel > AccessLevel.Contractor ? '10%' : '20%', format: true }];
  }
  //#endregion

  //#region Refractory details Backup grid
  refractoryBackupGridHeader() {
    this.refractoryBackupCols = [
      { field: 'product', header: 'Backup Product' },
      { field: 'area', header: 'Area (SqM)', format: true },
      { field: 'thickness', header: 'Thickness (mm)', format: true },
      { field: 'usageFactor', header: 'Usage Factor %', format: true },
      { field: 'volume', header: 'Volume (CuM)', format: true },
      { field: 'baseWeight', header: 'Base Wt (Kg)', format: true },
      { field: 'productCost', header: 'Cost($)', format: true }];
  }
  //#endregion

  //#region Refractory details hotface grid
  refractoryHotfaceGridHeader() {
    this.refractoryHotfaceCols = [
      { field: 'product', header: 'Hotface Product' },
      { field: 'area', header: 'Area (SqM)', format: true },
      { field: 'thickness', header: 'Thickness (mm)', format: true },
      { field: 'usageFactor', header: 'Usage Factor %', format: true },
      { field: 'volume', header: 'Volume (CuM)', format: true },
      { field: 'baseWeight', header: 'Base Wt (Kg)', format: true },
      { field: 'productCost', header: 'Cost($)', format: true }];
  }
  //#endregion

  resetEstimateRefractoryData() {
    this.anchorBackupGridData = [];
    this.anchorHotfaceGridData = [];
    this.refractoryBackupGridData = [];
    this.refractoryHotfaceGridData = [];
    this.repairArea = 0;
    this.vesselEstimateId = 0;
    this.estimateAnchorCostPerQty = 0;
    this.estimateRefractoryCostPerKg = 0;
    this.bk_gcf = 0;
    this.hf_gcf = 0;
    this.hotfaceRepairArea = 0;
    this.totalQtyPerZone = 0;
    this.totalKgPerZone = 0;
    this.totalAnchorCost = 0;
    this.totalRefractoryCost = 0;
    this.estimatedLabourCostByWeight = 0;
    this.estimatedLabourCostByQty = 0;
    this.totalLabourAndEqpCost = 0;
    this.totalCost = 0;
    this.tempRepairArea = 0;
    this.tempEstimateAnchorCostPerQty = 0;
    this.tempEstimateRefractoryCostPerKg = 0;
  }

  loadAnchorRefractoryProductsData(isDataCopy?: boolean): void {

    this.loading = true;
    if (this.validateFilterSelection()) {
      this.resetEstimateRefractoryData();
      var anchorBackupProductType = constProduct.anchorType;
      var anchorBackupProductPosition = constProduct.positionBackup;
      let anchorBackup = this.createSearchObj(anchorBackupProductType, anchorBackupProductPosition);

      var anchorHotfaceProductType = constProduct.anchorType;
      var anchorHotfaceProductPosition = constProduct.positionHotface;
      let anchorHotface = this.createSearchObj(anchorHotfaceProductType, anchorHotfaceProductPosition);

      var refractoryBackupProductType = constProduct.refractoryType;
      var refractoryBackupProductPosition = constProduct.positionBackup;
      let refractoryBackup = this.createSearchObj(refractoryBackupProductType, refractoryBackupProductPosition);

      var refractoryHotfaceProductType = constProduct.refractoryType;
      var refractoryHotfaceProductPosition = constProduct.positionHotface;
      let refractoryHotface = this.createSearchObj(refractoryHotfaceProductType, refractoryHotfaceProductPosition);

      let unitVesselZoneRevisionObj = this.createSearchObj("", "");

      this.refractoryEstimationService.getRefractoryEstimateAllProducts(anchorBackup,
        anchorHotface,
        refractoryBackup,
        refractoryHotface,
        unitVesselZoneRevisionObj).subscribe(
          (response) => {
            if (response) {
              this.loading = false;
              this.anchorBackupGridData = response[0];
              this.anchorHotfaceGridData = response[1];
              this.refractoryBackupGridData = response[2];
              this.refractoryHotfaceGridData = response[3];
              if (response[4] != null) {
                this.vesselEstimateId = response[4].id;
                this.repairArea = response[4].estimateRepairArea;
                this.estimateAnchorCostPerQty = response[4].estimateAnchorCostPerQty;
                this.estimateRefractoryCostPerKg = response[4].estimateRefractoryCostPerKg;
                let zoneId = response[4].zoneId
                this.checkWhetherEstimateRepairAreaModifiable(zoneId)
                //created below variable which will hold the data for comparison at patch update
                this.tempRepairArea = response[4].estimateRepairArea;
                this.tempEstimateAnchorCostPerQty = response[4].estimateAnchorCostPerQty;
                this.tempEstimateRefractoryCostPerKg = response[4].estimateRefractoryCostPerKg;

              }
              if (response[5] != null) {
                this.bk_gcf = response[5].bkGeometricCalcFactor;
                this.hf_gcf = response[5].hfGeometricCalcFactor;
              } else {
                this.bk_gcf = 0;
                this.hf_gcf = 0;
              }
              if (isDataCopy == true) {
                this.repairArea = response[6] != null ? response[6].repairArea : 0; //if nominal data is being copied
              }
              this.hotfaceRepairArea = response[6] != null ? response[6].repairArea : 0;
              // console.log(response)

              this.totalQtyPerZone = this.findSum(response[0], 'qty') + this.findSum(response[1], 'qty');
              this.totalKgPerZone = this.findSum(response[2], 'baseWeight') +
                this.findSum(response[3], 'baseWeight');
              this.totalAnchorCost = this.findSum(response[0], 'productCost') +
                this.findSum(response[1], 'productCost');
              this.totalRefractoryCost = this.findSum(response[2], 'productCost') +
                this.findSum(response[3], 'productCost');
              this.calculateCosting();

            } else {
              this.loading = true;
            }
          }
        )
    }
  }

  calculateCosting(): void {
    this.estimatedLabourCostByWeight = this.estimateRefractoryCostPerKg * this.totalKgPerZone;
    this.estimatedLabourCostByQty = this.estimateAnchorCostPerQty * this.totalQtyPerZone;

    this.totalLabourAndEqpCost = this.estimatedLabourCostByQty + this.estimatedLabourCostByWeight;
    this.totalCost = this.totalAnchorCost +
      this.totalRefractoryCost +
      this.totalLabourAndEqpCost;
  }

  getVesselEstimate() {
    var obj = this.createSearchObj("", "");
    this.refractoryEstimationService.getVesselEstimate(obj).subscribe(response => {
      if (response) {
        this.vesselEstimateId = response.id;
        this.repairArea = response.estimateRepairArea;
        this.estimateAnchorCostPerQty = response.estimateAnchorCostPerQty;
        this.estimateRefractoryCostPerKg = response.estimateRefractoryCostPerKg;
        //created below variable which will hold the data for comparison at patch update
        this.tempRepairArea = response.estimateRepairArea;
        this.tempEstimateAnchorCostPerQty = response.estimateAnchorCostPerQty;
        this.tempEstimateRefractoryCostPerKg = response.estimateRefractoryCostPerKg;
      }
    });
  }

  saveRevisionComment(showAlert?: string): void {
    var patchRevisionArrayObj = new Array<Object>();

    if (this.revisionComment != undefined && this.originalRevisionComment != undefined && this.revisionComment != this.originalRevisionComment) {
      patchRevisionArrayObj = this.getPatchRevisionArrayObj();
    }
    // console.log(patchRevisionArrayObj)

    if (patchRevisionArrayObj.length > 0) {
      this.shellService.showLoadingIndicator(true);
      var revisionId = this.selectedRevision ? this.selectedRevision.id : 0;
      this.refractoryEstimationService.updateVesselEstimateRevision(patchRevisionArrayObj, revisionId).subscribe({next:() => {
        this.shellService.showLoadingIndicator(false);
        for (var i = 0; i < this.revisions.length; i++) {
          if (this.revisions[i].id == revisionId) {
            this.revisions[i].revisionComment = this.revisionComment;
          }
          // this.alertService.openAlert("Success!", "Revision comment saved successfully!", "success");
          this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Revision comment saved successfully!', severity: 'success' })
        }
        this.originalRevisionComment = this.revisionComment;
      }, error:() => {
        this.shellService.showLoadingIndicator(false);
      }});
    }
  }

  tempPatchArrayObj: any;
  saveEstimates(showAlert?: string): void {
    var isRepairAreaValid = true;
    if (this.repairArea > 0) {
      if (this.anchorBackupGridData) {
        for (var i = 0; i < this.anchorBackupGridData.length; i++) {
          var gridRepairArea = this.anchorBackupGridData[i].area;
          if (gridRepairArea > this.repairArea) {
            isRepairAreaValid = false;
          }
        }
      }
      if (this.anchorHotfaceGridData) {
        for (var i = 0; i < this.anchorHotfaceGridData.length; i++) {
          var gridRepairArea = this.anchorHotfaceGridData[i].area;
          if (gridRepairArea > this.repairArea) {
            isRepairAreaValid = false;
          }
        }
      }
      if (this.refractoryBackupGridData) {
        for (var i = 0; i < this.refractoryBackupGridData.length; i++) {
          var gridRepairArea = this.refractoryBackupGridData[i].area;
          if (gridRepairArea > this.repairArea) {
            isRepairAreaValid = false;
          }
        }
      }
      if (this.refractoryHotfaceGridData) {
        for (var i = 0; i < this.refractoryHotfaceGridData.length; i++) {
          var gridRepairArea = this.refractoryHotfaceGridData[i].area;
          if (gridRepairArea > this.repairArea) {
            isRepairAreaValid = false;
          }
        }
      }

      if (isRepairAreaValid) {
        var vesselEstimateObj = {
          id: this.vesselEstimateId,
          estimateRepairArea: this.repairArea,
          estimateAnchorCostPerQty: this.estimateAnchorCostPerQty,
          estimateRefractoryCostPerKg: this.estimateRefractoryCostPerKg,
          vesselEstimateRevisionId: this.selectedRevision ? this.selectedRevision.id : 0,
          SiteUnitId: this.shellService.loadGlobalSiteUnitOutage().unitId,
          outageId: this.shellService.loadGlobalSiteUnitOutage().outageId,
          vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
          zoneId: this.selectedZone ? this.selectedZone.id : 0,
          active: true,
          createdDate: new Date()
        }
        this.shellService.showLoadingIndicator(true);
        if (this.vesselEstimateId > 0) {
          this.tempPatchArrayObj = [];
          var patchArrayObj = [];

          if (this.repairArea != this.tempRepairArea) {
            this.tempPatchArrayObj.push(this.shellService.createPatchArray("estimateRepairArea", this.repairArea));
          }
          if (this.estimateAnchorCostPerQty != this.tempEstimateAnchorCostPerQty) {
            this.tempPatchArrayObj.push(this.shellService.createPatchArray("estimateAnchorCostPerQty", this.estimateAnchorCostPerQty));
          }
          if (this.estimateRefractoryCostPerKg != this.tempEstimateRefractoryCostPerKg) {
            this.tempPatchArrayObj.push(this.shellService.createPatchArray("estimateRefractoryCostPerKg", this.estimateRefractoryCostPerKg));
          }
          this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()));
          for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
            patchArrayObj.push(this.tempPatchArrayObj[i][0]);
          }

          if (patchArrayObj.length > 0) {
            this.refractoryEstimationService.updateVesselEstimate(patchArrayObj, this.vesselEstimateId).subscribe({next:() => {
              this.shellService.showLoadingIndicator(false);
              this.getVesselEstimate();
              if (showAlert) {
                // this.alertService.openAlert("Success!", "Updated successfully!", "success");
                this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Updated successfully!', severity: 'success' })
              }
            }, error:() => {
              this.shellService.showLoadingIndicator(false);
            }});
          }
        } else {
          this.refractoryEstimationService.saveVesselEstimate(vesselEstimateObj).subscribe({next:() => {
            this.shellService.showLoadingIndicator(false);
            this.getVesselEstimate();
            if (showAlert) {
              // this.alertService.openAlert("Success!", "Saved successfully!", "success");
              this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Saved successfully!', severity: 'success' })
            }
          }, error:() => {
            this.shellService.showLoadingIndicator(false);
          }});
        }
      } else {
        // this.alertService.openAlert("Validation!", "Estimated Repair Area input is less than the Area for Anchor/Refractory product!", "warning");
        this.shellService.alert(this.dialogService, { title: 'Validation!', message: 'Estimated Repair Area input is less than the Area for Anchor/Refractory product', severity: 'warning' })
        return; //stop save/update
      }
    }
    else {
      // this.alertService.openAlert("Validation!", "Estimated Repair Area is required!", "warning");
      this.shellService.alert(this.dialogService, { title: 'Validation!', message: 'Estimated Repair Area is required!', severity: 'warning' })
      return;
    }
  }

  getPatchRevisionArrayObj() {
    var tempPatchRevisionArrayObj = [];
    var patchRevisionArrayObj = [];

    tempPatchRevisionArrayObj.push(this.shellService.createPatchArray("revisionNo", this.selectedRevision?.revisionNo));
    tempPatchRevisionArrayObj.push(this.shellService.createPatchArray("revisionComment", this.revisionComment));
    tempPatchRevisionArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()));

    for (var i = 0; i < tempPatchRevisionArrayObj.length; i++) {   //pushing the 0th
      patchRevisionArrayObj.push(tempPatchRevisionArrayObj[i][0]);
    }

    return patchRevisionArrayObj;
  }

  findSum(data: any, field: string): number {
    let total = 0;
    if (data != null) {
      for (let i = 0; i < data.length; i++) {
        total += data[i] ? data[i][field] : 0
      }
    }
    return total;
  }

  estimateRepairAreaChange(event: any) {
    if (event.value > 0) {
      this.isEstimateRepairAreaEmpty = false;
    } else {
      this.isEstimateRepairAreaEmpty = true;
    }
  }

  // Nominal dialog
  toggleEstimateRefractoryProductDialog(productType: string, productPosition: string, productName?: string, spacing?: string) {
    if (!this.validateFilterSelection()) {
      return;
    }
    if (this.repairArea > 0) {
      this.estimateRefractoryProductDialog.opened = !this.estimateRefractoryProductDialog.opened;
      this.DialogProductType = productType;
      this.DialogProductPosition = productPosition;
      if (!productName) { //productname will have data only at update time
        this.anchorRefractoryObj.area = this.repairArea;
      }

      if (productType == 'Anchor' && this.isProductDataLoaded == false) {
        this.loadProductDropdownData('Anchor', productName);
      } else {
        if (productType == 'Anchor' && this.isProductDataLoaded == true) {
          this.bindAnchorProducts(productName);
        }
      }
      var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage();
      if (siteUnitOutage.siteId > 0 && siteUnitOutage.countryId > 0) {
        if (productType == 'Anchor' && this.isSpacingDataLoaded == false) {
          this.loadSpacingDropdownData(siteUnitOutage.countryId, 1, spacing);// 1 is enum code for spacing
        } else {
          if (this.isSpacingDataLoaded == true) {
            this.bindSelectedSpacing(spacing);
          }
        }
        if (this.isAnchorDensityThicknessCostPerTonneConversionDataLoaded == false) {
          this.getAnchorDensityThicknessCostPerTonneConversion(siteUnitOutage.countryId, 2); ////2 is enum code 
        }
        if (productType == 'Refractory' && this.isRefractoryProductDataLoaded == false) {
          this.loadProductDropdownData('Refractory', productName);
        } else {
          if (productType == 'Refractory' && this.isRefractoryProductDataLoaded == true) {
            this.bindRefractoryProduct(productName);
          }
        }
      }
    } else {
      this.isEstimateRepairAreaEmpty = true;
    }
  }

  validateEstimateRefractoryDetails() {
    var isValid = true;
    var product = this.selectedProduct ? this.selectedProduct.name : "";
    var area = this.anchorRefractoryObj.area ? this.anchorRefractoryObj.area : 0;

    if (product) {
      this.estimateRefractoryProductDialog.isProductEmpty = false;
    } else {
      if (this.estimateRefractoryProductDialog.isUpdate && !this.gridRowData.active) {
        this.estimateRefractoryProductDialog.isProductEmpty = false;
      } else {
        this.estimateRefractoryProductDialog.isProductEmpty = true;
        isValid = false;
      }
    }

    if (area) {
      if (this.repairArea < area) { //before checking this make sure we have repair area
        this.estimateRefractoryProductDialog.isAreaGreaterThanRepairArea = "Area can not be greater than repair area";
        this.estimateRefractoryProductDialog.isAreaEmpty = true;
        isValid = false;
      } else {
        this.estimateRefractoryProductDialog.isAreaGreaterThanRepairArea = "";
        this.estimateRefractoryProductDialog.isAreaEmpty = false;
      }
    } else {
      this.estimateRefractoryProductDialog.isAreaEmpty = false;
      isValid = false;
    }
    return isValid;
  }

  // Saves estimate refractory product details
  saveEstimateRefractoryProductDetails() {
    if (!this.validateEstimateRefractoryDetails()) {
      return;
    }

    let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage();

    if (siteUnitOutage.unitId > 0 && siteUnitOutage.outageId > 0) {
      if ((this.repairArea != this.tempRepairArea || this.estimateAnchorCostPerQty != this.tempEstimateAnchorCostPerQty || this.estimateRefractoryCostPerKg != this.tempEstimateRefractoryCostPerKg) || this.addNewRecordMode == true) {
        this.saveEstimates(); //this will save or update the vessel estimate 
      }

      let anchorRefractoryViewModel: AnchorRefractoryViewModel = {
        id: 0,
        vesselEstimateRevisionId: this.selectedRevision != undefined ? this.selectedRevision.id : 0,
        siteUnitId: siteUnitOutage.unitId,
        outageId: siteUnitOutage.outageId,
        vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
        zoneId: this.selectedZone ? this.selectedZone.id : 0,
        productId: this.selectedProduct.id,
        productPosition: this.DialogProductPosition,
        thickness: this.anchorRefractoryObj.thickness ? this.anchorRefractoryObj.thickness : 0,
        anchorSpacing: this.selectedSpacing ? this.selectedSpacing.name : 0,
        area: this.anchorRefractoryObj.area,
        qty: this.anchorRefractoryObj.qty,
        productCost: this.anchorRefractoryObj.productCost,
        usageFactor: this.anchorRefractoryObj.usageFactor,
        volume: this.anchorRefractoryObj.volume,
        baseWeight: this.anchorRefractoryObj.baseWeight,
        active: true,
        createdDate: new Date()
      }
      this.refractoryEstimationService.addAnchorRefractoryDetails(anchorRefractoryViewModel).subscribe({next:(response) => {
        this.isSpacingDataLoaded = false;
        this.isProductDataLoaded = false;
        this.estimateRefractoryProductDialog.opened = false;
        // this.alertService.openAlert("Success!", "Saved successfully!", "success");
        this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Saved successfully!', severity: 'success' })
        this.resetEstimateRefractoryDetails();
        var needsLoading = false;
        if (this.DialogProductType == 'Anchor' && anchorRefractoryViewModel.productPosition == 'Backup') {
          needsLoading = true;
        }
        if (this.DialogProductType == 'Anchor' && anchorRefractoryViewModel.productPosition == 'Hotface') {
          needsLoading = true;
        }
        if (this.DialogProductType == 'Refractory' && anchorRefractoryViewModel.productPosition == 'Backup') {
          needsLoading = true;
        }
        if (this.DialogProductType == 'Refractory' && anchorRefractoryViewModel.productPosition == 'Hotface') {
          needsLoading = true;
        }
        if (needsLoading) {
          this.loadAnchorRefractoryProductsData();
        }
        this.DialogProductType = "";
        this.DialogProductPosition = "";
      }, error:(error) => {
        // this.alertService.openAlert("Error!", error, "error");
        this.shellService.alert(this.dialogService, { title: 'Error!', message: error, severity: 'error' })
        this.DialogProductType = "";
        this.DialogProductPosition = "";
        this.isSpacingDataLoaded = false;
        this.isProductDataLoaded = false;
        this.estimateRefractoryProductDialog.opened = false;
        this.resetEstimateRefractoryDetails();
      }})
    }
  }

  updateEstimateRefractoryProductDetails() {
    if (!this.validateEstimateRefractoryDetails()) {
      return;
    }
    if (this.repairArea != this.tempRepairArea || this.estimateAnchorCostPerQty != this.tempEstimateAnchorCostPerQty || this.estimateRefractoryCostPerKg != this.tempEstimateRefractoryCostPerKg) {
      this.saveEstimates(); //this will save or update the vessel estimate 
    }
    let estimateRefractoryProduct = {
      id: this.id,
      vesselEstimateRevisionId: this.selectedRevision?.id,
      productName: this.selectedProduct ? this.selectedProduct.name : "",
      productId: this.selectedProduct ? this.selectedProduct.id : 0,
      productPosition: this.DialogProductPosition,
      thickness: this.anchorRefractoryObj.thickness ? this.anchorRefractoryObj.thickness : 0,
      anchorSpacing: this.selectedSpacing ? this.selectedSpacing.name : 0,
      area: this.anchorRefractoryObj.area,
      qty: this.anchorRefractoryObj.qty ? this.anchorRefractoryObj.qty : 0,
      productCost: this.anchorRefractoryObj.productCost,
      usageFactor: this.anchorRefractoryObj.usageFactor,
      volume: this.anchorRefractoryObj.volume,
      baseWeight: this.anchorRefractoryObj.baseWeight,
    }
    var estimateRefractoryViewModel = this.bindEstimateRefractoryPatchObject(estimateRefractoryProduct);
    if (estimateRefractoryViewModel.length > 0) {
      this.refractoryEstimationService.updateAnchorRefractoryDetails(estimateRefractoryProduct.id, estimateRefractoryViewModel).subscribe({next:(response) => {
        this.isSpacingDataLoaded = false;
        this.isProductDataLoaded = false;
        this.estimateRefractoryProductDialog.opened = false;
        // this.alertService.openAlert("success!", "Updated successfully!", "success");
        this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Updated successfully!', severity: 'success' })
        this.resetEstimateRefractoryDetails();
        var needsLoading = false;
        if (this.DialogProductType == constProduct.anchorType && estimateRefractoryProduct.productPosition == constProduct.positionBackup) {
          needsLoading = true;
        }
        if (this.DialogProductType == constProduct.anchorType && estimateRefractoryProduct.productPosition == constProduct.positionHotface) {
          needsLoading = true;
        }
        if (this.DialogProductType == constProduct.refractoryType && estimateRefractoryProduct.productPosition == constProduct.positionBackup) {
          needsLoading = true;
        }
        if (this.DialogProductType == constProduct.refractoryType && estimateRefractoryProduct.productPosition == constProduct.positionHotface) {
          needsLoading = true;
        }
        if (needsLoading) {
          this.loadAnchorRefractoryProductsData();
        }
        this.DialogProductType = "";
        this.DialogProductPosition = "";
      }, error:(error) => {
        // this.alertService.openAlert("Error!", error, "error");
        this.shellService.alert(this.dialogService, { title: 'Error!', message: error, severity: 'error' })
        this.DialogProductType = "";
        this.DialogProductPosition = "";
        this.isSpacingDataLoaded = false;
        this.isProductDataLoaded = false;
        this.estimateRefractoryProductDialog.opened = false;
        this.resetEstimateRefractoryDetails();
      }})
    }
  }

  bindAnchorProducts(productName?: string) {
    if (this.products) {
      let rowIndex = this.products.findIndex(x => x.name == productName);
      this.selectedProduct = this.products[rowIndex];
    }
  }

  bindRefractoryProduct(productName?: string) {
    if (this.refractoryProducts) {
      let rowIndex = this.refractoryProducts.findIndex(x => x.name == productName);
      this.selectedProduct = this.refractoryProducts[rowIndex];
    }
  }

  bindSelectedSpacing(spacing?: string) {
    if (this.spacings) {
      let rowIndex = this.spacings.findIndex(x => x.name == spacing);
      this.selectedSpacing = this.spacings[rowIndex];
    }
  }

  loadProductDropdownData(productType: string, productName?: string): void {
    this.productService.getProductsByProductType(productType, this.siteUnitOutageObj.countryId).subscribe(response => {
      if (productType == 'Anchor') {
        this.products = [];
        this.products = response;
        this.isProductDataLoaded = true;
        if (productName) {
          this.bindAnchorProducts(productName);
        }
      }
      if (productType == 'Refractory') {
        this.refractoryProducts = [];
        this.refractoryProducts = response;
        this.isRefractoryProductDataLoaded = true;
        if (productName) {
          this.bindRefractoryProduct(productName);
        }
      }
    })
  }

  loadSpacingDropdownData(countryId: number, paramName: number, spacing?: string): void {
    this.spacings = [];
    this.appSettings.getSpacing(countryId, paramName).subscribe(response => {
      if (response) {
        for (var i = 0; i < response.length; i++) {
          this.spacings.push({ name: response[i] });
        }
      }
      if (spacing) {
        this.bindSelectedSpacing(spacing);
      }
      this.isSpacingDataLoaded = true;
    })
  }

  estimateRefractoryProductDialogControlChange(event: any, type: string, fieldName: string) {
    var isQty = false;
    if (fieldName == 'qty') {
      this.anchorRefractoryObj.qty = event.value;
      isQty = true;
    }
    if (fieldName == 'area') {
      this.anchorRefractoryObj.area = event.value;
    }
    if (fieldName == 'thickness') {
      this.anchorRefractoryObj.thickness = event.value;
    }
    if (fieldName == 'usageFactor') {
      this.anchorRefractoryObj.usageFactor = event.value;
    }
    if (type == 'Anchor') {
      this.calculteAnchorDetails(isQty);
    }
    else if (type == 'Refractory') {
      this.calculteRefractoryDetails();
    }
  }

  //Calculation of GcfFactor
  calculateGcfFactor(check: any) {
    var gcfFactor = 0;
    if (check == 'bk') {
      gcfFactor = this.bk_gcf;

    } else if (check == 'hf') {
      gcfFactor = this.hf_gcf;
    } else {
      if (this.DialogProductPosition == 'Backup') {
        gcfFactor = this.bk_gcf;
      } else if (this.DialogProductPosition == 'Hotface') {
        gcfFactor = this.hf_gcf;
      }
    }
    if (!gcfFactor || gcfFactor == 0) {
      gcfFactor = 1; //set default if there's no gcfFactor present
    }
    return gcfFactor;
  }

  //calculate the Anchor Quantity
  calculateAnchorQuantity(gcfFactor: number, spacing: any, area: number, updateCostOnly: boolean): any {
    var quantity = this.anchorRefractoryObj.qty;
    var anchorDensity = this.anchorDensity ? this.anchorDensity : 0;
    var spacingX = 0, spacingY = 0;

    var spacingArray = spacing.split('X');
    if (spacingArray.length >= 1) {
      spacingX = parseInt(spacingArray[0].trim());
      spacingY = parseInt(spacingArray[1].trim());
    }

    //Calculate density
    var spacingMultiplier = (spacingX * spacingY);
    var density = 0;
    if (spacingMultiplier != 0) {
      density = anchorDensity / (spacingX * spacingY);
    }

    //calculate quantity
    if (!updateCostOnly) {
      quantity = Math.floor(area * density * gcfFactor);
    }

    return quantity;
  }

  //Calculation of cost for anchor
  calculateAnchorCost(quantity: any, selectedProduct: any, products: Product[]) {
    var product = selectedProduct ? selectedProduct.name : "";
    var productCost = 0;
    var costPerUnit = 0;

    if (products.length > 0) {
      var row = products.find(r => r.name == product);
      costPerUnit = row ? row.costPerUnit : 0;
    }

    //Ensure that this is not null
    costPerUnit = costPerUnit ? costPerUnit : 0;
    productCost = quantity * costPerUnit;

    return productCost;
  }

  calculteAnchorDetails(updateCostOnly: boolean) {
    var product = this.selectedProduct ? this.selectedProduct.name : "";
    var area = this.anchorRefractoryObj.area;
    var spacing = this.selectedSpacing ? this.selectedSpacing.name : "";

    var gcfFactor = this.calculateGcfFactor("check");

    if (product && area && spacing) { //calculation will be done based on these field

      var quantity = this.calculateAnchorQuantity(gcfFactor, spacing, area, updateCostOnly);
      this.anchorRefractoryObj.qty = quantity;

      //Calculate cost
      var productCost = this.calculateAnchorCost(quantity, this.selectedProduct, this.products);
      this.anchorRefractoryObj.productCost = productCost ? productCost : 0;
    }
  }

  //calculation of volume for refractory
  calculateVolume(thickness: any, thicknessConversion: any, area: any, gcfFactor: any): number {
    var thicknessFactor = 1;

    if (thicknessConversion != 0) {
      thicknessFactor = thickness / thicknessConversion;
    }

    var volume = area * gcfFactor * thicknessFactor;

    return volume;
  }

  //calculation of weight for refeactory
  calculateWeight(usageFactor: any, product: any, volume: any, refractoryProducts: Product[]): number {
    var publishedDensity = 0;
    this.costPerUnit = 0;

    if (refractoryProducts.length > 0) {
      var row = refractoryProducts.find(r => r.name == product);
      this.costPerUnit = row ? row.costPerUnit : 0;
      var val = row ? row.publishedDensity : 0;
      publishedDensity = val ? val : 0;
    }

    var weight = volume * publishedDensity;

    if (usageFactor != 0) {
      weight = weight + ((weight * usageFactor) / 100);
    }

    weight = Math.floor(weight);

    return weight;
  }

  // calculation of cost for refractory
  calculateRefractoryCost(weight: any): number {
    var cost = 0;

    //Ensure that this is not null
    this.costPerUnit = this.costPerUnit ? this.costPerUnit : 0;

    cost = weight * this.costPerUnit;
    cost = cost / this.costPerTonne;

    return cost;
  }

  calculteRefractoryDetails() {
    var product = this.selectedProduct ? this.selectedProduct.name : "";
    var area = this.anchorRefractoryObj.area;
    var thickness = this.anchorRefractoryObj.thickness ? this.anchorRefractoryObj.thickness : 0;
    var usageFactor = this.anchorRefractoryObj.usageFactor ? this.anchorRefractoryObj.usageFactor : 0;
    var thicknessConversion = this.thicknessConversion ? this.thicknessConversion : 0;

    var gcfFactor = this.calculateGcfFactor("check");

    if (product && area > 0 && thickness > 0) { //calculation will be done based on these field
      var volume = this.calculateVolume(thickness, thicknessConversion, area, gcfFactor);
      this.anchorRefractoryObj.volume = volume ? volume : 0;

      var weight = this.calculateWeight(usageFactor, product, volume, this.refractoryProducts);
      this.anchorRefractoryObj.baseWeight = weight;

      var cost = this.calculateRefractoryCost(weight);
      this.anchorRefractoryObj.productCost = cost ? cost : 0;
    }
  }

  setGridArea(): void {
    forkJoin(this.productService.getProductsByProductType('Anchor', this.siteUnitOutageObj.countryId),
      this.productService.getProductsByProductType('Refractory', this.siteUnitOutageObj.countryId))
      .subscribe(([anchorProductResponse, refractoryProductResponse]: [Product[], Product[]]) => {
        // Calculate Anchor grid based on new grid area
        for (let i = 0; this.anchorBackupGridData && i < this.anchorBackupGridData.length; i++) {
          var product = this.anchorBackupGridData[i].product;
          var area = this.anchorBackupGridData[i].area;
          var spacing = this.anchorBackupGridData[i].anchorSpacing;
          var quantity = this.anchorBackupGridData[i].qty;

          var gcfFactor = this.calculateGcfFactor("bk")
          if (product && area != null && spacing) { //calculation will be done based on these field

            this.anchorBackupGridData[i].area = this.repairArea;
            var quantity = this.calculateAnchorQuantity(gcfFactor, spacing, this.repairArea, false);
            this.anchorBackupGridData[i].qty = quantity;

            if (this.isProductDataLoaded == false) {
              this.loadProductDropdownData('Anchor', product);
              this.bindAnchorProducts(product);
            }
            else {
              if (this.isProductDataLoaded == true) {
                this.bindAnchorProducts(product);
              }
            }
            if (this.selectedProduct) {
              var productCost = this.calculateAnchorCost(quantity, this.selectedProduct, anchorProductResponse);
              this.anchorBackupGridData[i].productCost = productCost ? productCost : 0;
            }
            else {
              this.loadProductDropdownData('Anchor', product);
              this.bindAnchorProducts(product);
            }
            //Calculate cost
            //var productCost = this.calculateAnchorCost(quantity, this.selectedProduct, anchorProductResponse);
            //this.anchorBackupGridData[i].productCost = productCost ? productCost : 0;
          }
        }
        for (let i = 0; this.anchorHotfaceGridData && i < this.anchorHotfaceGridData.length; i++) {
          var product = this.anchorHotfaceGridData[i].product;
          var area = this.anchorHotfaceGridData[i].area;
          var spacing = this.anchorHotfaceGridData[i].anchorSpacing;
          var quantity = this.anchorHotfaceGridData[i].qty;

          var gcfFactor = this.calculateGcfFactor("hf")
          if (product && area != null && spacing) { //calculation will be done based on these field

            this.anchorHotfaceGridData[i].area = this.repairArea;
            var quantity = this.calculateAnchorQuantity(gcfFactor, spacing, this.repairArea, false);
            this.anchorHotfaceGridData[i].qty = quantity;

            if (this.isProductDataLoaded == false) {
              this.loadProductDropdownData('Anchor', product);
              this.bindAnchorProducts(product);
            }
            else {
              if (this.isProductDataLoaded == true) {
                this.bindAnchorProducts(product);
              }
            }
            if (this.selectedProduct) {
              var productCost = this.calculateAnchorCost(quantity, this.selectedProduct, anchorProductResponse);
              this.anchorHotfaceGridData[i].productCost = productCost ? productCost : 0;
            }
            else {
              this.loadProductDropdownData('Anchor', product);
              this.bindAnchorProducts(product);
            }
            ////Calculate cost
            //var productCost = this.calculateAnchorCost(quantity, this.selectedProduct, anchorProductResponse);
            //this.anchorHotfaceGridData[i].productCost = productCost ? productCost : 0;
          }
        }
        // Refractory table calculations
        for (let i = 0; this.refractoryBackupGridData && i < this.refractoryBackupGridData.length; i++) {
          var product = this.refractoryBackupGridData[i].product;
          var area = this.refractoryBackupGridData[i].area;
          var thickness = this.refractoryBackupGridData[i].thickness ? this.refractoryBackupGridData[i].thickness : 0;
          var usageFactor = this.refractoryBackupGridData[i].usageFactor ? this.refractoryBackupGridData[i].usageFactor : 0;
          var thicknessConversion = this.thicknessConversion ? this.thicknessConversion : 0;

          var gcfFactor = this.calculateGcfFactor("bk")
          if (product && area != null && this.repairArea <= this.hotfaceRepairArea && thickness > 0) { //calculation will be done based on these field
            //Calculate volume
            this.refractoryBackupGridData[i].area = this.repairArea;
            var volume = this.calculateVolume(thickness, thicknessConversion, this.repairArea, gcfFactor);
            this.refractoryBackupGridData[i].volume = volume ? volume : 0

            //Calculate weight
            var weight = this.calculateWeight(usageFactor, product, volume, refractoryProductResponse);
            this.refractoryBackupGridData[i].baseWeight = weight;

            //Calculate Cost
            var cost = this.calculateRefractoryCost(weight);
            this.refractoryBackupGridData[i].productCost = cost ? cost : 0;
          }
        }
        for (let i = 0; this.refractoryHotfaceGridData && i < this.refractoryHotfaceGridData.length; i++) {
          var product = this.refractoryHotfaceGridData[i].product;
          var area = this.refractoryHotfaceGridData[i].area;
          var thickness = this.refractoryHotfaceGridData[i].thickness ? this.refractoryHotfaceGridData[i].thickness : 0;
          var usageFactor = this.refractoryHotfaceGridData[i].usageFactor ? this.refractoryHotfaceGridData[i].usageFactor : 0;
          var thicknessConversion = this.thicknessConversion ? this.thicknessConversion : 0;

          var gcfFactor = this.calculateGcfFactor("hf");
          if (product && area != null && this.repairArea <= this.hotfaceRepairArea && thickness > 0) { //calculation will be done based on these field
            //Calculate volume
            this.refractoryHotfaceGridData[i].area = this.repairArea;
            var volume = this.calculateVolume(thickness, thicknessConversion, this.repairArea, gcfFactor);
            this.refractoryHotfaceGridData[i].volume = volume ? volume : 0;

            //Calculate weight
            var weight = this.calculateWeight(usageFactor, product, volume, refractoryProductResponse);
            this.refractoryHotfaceGridData[i].baseWeight = weight;

            //Calculate Cost
            var cost = this.calculateRefractoryCost(weight);
            this.refractoryHotfaceGridData[i].productCost = cost ? cost : 0;
          }
        }
        this.loading = false;
        this.saveGrid();
      })
    this.loading = true;
  }

  saveGrid(): void {
    var gridDataArray = [];
    this.shellService.showLoadingIndicator(true);
    if (this.anchorBackupGridData) {
      for (let i = 0; i < this.anchorBackupGridData.length; i++) {
        gridDataArray.push(this.anchorBackupGridData[i]);
      }
    }
    if (this.anchorHotfaceGridData) {
      for (let i = 0; i < this.anchorHotfaceGridData.length; i++) {
        gridDataArray.push(this.anchorHotfaceGridData[i]);
      }
    }
    if (this.refractoryBackupGridData) {
      for (let i = 0; i < this.refractoryBackupGridData.length; i++) {
        gridDataArray.push(this.refractoryBackupGridData[i]);
      }
    }
    if (this.refractoryHotfaceGridData) {
      for (let i = 0; i < this.refractoryHotfaceGridData.length; i++) {
        gridDataArray.push(this.refractoryHotfaceGridData[i]);
      }
    }
    if (gridDataArray.length > 0) {
      this.refractoryEstimationService.saveRefractoryDetails(gridDataArray,
        this.vesselEstimateId,
        this.repairArea).subscribe(() => {
          this.shellService.showLoadingIndicator(false);
          this.loadAnchorRefractoryProductsData();
        });
    } else {
      this.shellService.showLoadingIndicator(false);
    }
  }

  getAnchorDensityThicknessCostPerTonneConversion(countryId: number, paramName: number): void {
    this.appSettings.getAppsettings(countryId, paramName).subscribe(response => {
      if (response) {
        this.anchorDensity = parseInt(response[0]);
        this.thicknessConversion = parseInt(response[1]);
        this.costPerTonne = parseInt(response[2]);
        this.isAnchorDensityThicknessCostPerTonneConversionDataLoaded = true;
      }
    })
  }

  bindEstimateRefractoryPatchObject(nominal: any): any[] {
    let tempPatchArrayObj: any[] = [];
    var patchArrayObj = [];
    if (nominal.id != this.gridRowData.id) {
      tempPatchArrayObj.push(this.shellService.createPatchArray("id", nominal.id))
    }
    if (nominal.productName != this.gridRowData.productName && nominal.productName != "" && nominal.productId > 0) {
      tempPatchArrayObj.push(this.shellService.createPatchArray("productId", nominal.productId))
    }
    if (nominal.qty != this.gridRowData.qty) {
      tempPatchArrayObj.push(this.shellService.createPatchArray("qty", nominal.qty))
    }
    if (nominal.area != this.gridRowData.area) {
      tempPatchArrayObj.push(this.shellService.createPatchArray("area", nominal.area))
    }
    if (nominal.productCost != this.gridRowData.productCost) {
      tempPatchArrayObj.push(this.shellService.createPatchArray("productCost", nominal.productCost))
    }
    if (nominal.thickness != this.gridRowData.thickness) {
      tempPatchArrayObj.push(this.shellService.createPatchArray("thickness", nominal.thickness))
    }

    if (this.DialogProductType == 'Anchor') {
      if (nominal.anchorSpacing != this.gridRowData.anchorSpacing) {
        tempPatchArrayObj.push(this.shellService.createPatchArray("anchorSpacing", nominal.anchorSpacing))
      }
    }
    if (this.DialogProductType == 'Refractory') {
      if (nominal.usageFactor != this.gridRowData.usageFactor) {
        tempPatchArrayObj.push(this.shellService.createPatchArray("usageFactor", nominal.usageFactor))
      }
      if (nominal.volume != this.gridRowData.volume) {
        tempPatchArrayObj.push(this.shellService.createPatchArray("volume", nominal.volume))
      }
      if (nominal.baseWeight != this.gridRowData.baseWeight) {
        tempPatchArrayObj.push(this.shellService.createPatchArray("baseWeight", nominal.baseWeight))
      }
    }
    tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))

    if (tempPatchArrayObj.length > 1) {
      for (var i = 0; i < tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  deleteConfirm() {
    if (this.deleteObj.estimateRefractoryId) {
      this.refractoryEstimationService.deleteAnchorRefractoryDetails(this.deleteObj.estimateRefractoryId).subscribe(response => {
        // this.alertService.openAlert("Deleted!", "Product deleted successfully!", "success");
        this.shellService.alert(this.dialogService, { title: 'Deleted!', message: 'Product deleted successfully!', severity: 'success' })
        var needsLoading = false;
        if (this.deleteObj.productType == constProduct.anchorType && this.deleteObj.productPosition == constProduct.positionBackup) {
          needsLoading = true;
        }
        if (this.deleteObj.productType == constProduct.anchorType && this.deleteObj.productPosition == constProduct.positionHotface) {
          needsLoading = true;
        }
        if (this.deleteObj.productType == constProduct.refractoryType && this.deleteObj.productPosition == constProduct.positionBackup) {
          needsLoading = true;
        }
        if (this.deleteObj.productType == constProduct.refractoryType && this.deleteObj.productPosition == constProduct.positionHotface) {
          needsLoading = true;
        }
        if (needsLoading) {
          this.loadAnchorRefractoryProductsData();
        }
        this.deleteObj = {};
      })
    }
  }

  resetEstimateRefractoryDetails() {
    this.selectedProduct = [];
    this.selectedSpacing = [];
    this.anchorRefractoryObj = new AnchorRefractoryViewModel();
    this.estimateRefractoryProductDialog.isProductEmpty = false;
    this.estimateRefractoryProductDialog.isAreaEmpty = false;
    this.estimateRefractoryProductDialog.isAreaGreaterThanRepairArea = "";
    this.estimateRefractoryProductDialog.isUpdate = false;
  }

  toggleVesselProductGrid(): void {
    this.showingVesselProductGrid = !this.showingVesselProductGrid;
    this.addVesselProductNewRecordMode = false;
    this.isVesselAddNewMode = false;
    var selectedRevision = this.selectedRevision ? this.selectedRevision.id : "";
    if (this.showingVesselProductGrid) {
      if (selectedRevision) {
        this.addNewVesselProductRecordToggle();
        this.vesselProductGridHeader();
      }
    } else {
      this.onRevisionChange(null);
      this.addNewRecordMode = false;
    }
  }

  productTypes: ProductType[] = [];
  productCategories: Category[] = [];
  vesselProducts: any;
  productDropList: Product[] = [];
  selectedProductType: any;
  selectedProductCategory: any;
  selectedVesselProduct: any;
  isVesselAddNewMode: boolean = false;

  vesselProductDialog = {
    opened: false,
    isProductTypeEmpty: false,
    isProductCategoryEmpty: false,
    isProductEmpty: false,
    isQuantityEmpty: false,
    isLabourHoursEmpty: false,
    isAmountWorkerEmpty: false,
    isUpdate: false
  }

  //validate vessel product
  validateVesselProduct() {
    var isValid = true;
    var productType = this.selectedProductType ? this.selectedProductType.name : "";
    if (productType) {
      this.vesselProductDialog.isProductTypeEmpty = false;
    } else {
      this.vesselProductDialog.isProductTypeEmpty = true;
      isValid = false;
    }
    var productCategory = this.selectedProductCategory ? this.selectedProductCategory.name : "";
    if (productCategory) {
      this.vesselProductDialog.isProductCategoryEmpty = false;
    } else {
      this.vesselProductDialog.isProductCategoryEmpty = true;
      isValid = false;
    }
    var vesselProduct = this.selectedVesselProduct ? this.selectedVesselProduct.name : "";
    if (vesselProduct) {
      this.vesselProductDialog.isProductEmpty = false;
    } else {
      this.vesselProductDialog.isProductEmpty = true;
      isValid = false;
    }
    if (this.vesselProductObj.quantity > 0) {
      this.vesselProductDialog.isQuantityEmpty = false;
    } else {
      this.vesselProductDialog.isQuantityEmpty = true;
      isValid = false;
    }
    if (this.vesselProductObj.labourhours > 0) {
      this.vesselProductDialog.isLabourHoursEmpty = false;
    } else {
      this.vesselProductDialog.isLabourHoursEmpty = true;
      isValid = false;
    }
    if (this.vesselProductObj.amountWorker > 0) {
      this.vesselProductDialog.isAmountWorkerEmpty = false;
    } else {
      this.vesselProductDialog.isAmountWorkerEmpty = true;
      isValid = false;
    }

    return isValid;
  }

  toggleVesselProductDialog() {
    if (!this.validateFilterSelection(true)) {
      return;
    }
    this.vesselProductDialog.opened = !this.vesselProductDialog.opened;
    if (this.vesselProductDialog.opened) {
      this.vesselProductDialog.isUpdate = false;
      this.resetVesselProuctDetail();
      this.getProductTypes();
    }
  }

  getProductTypes(productType?: string, productCategory?: string, product?: string): void {
    this.shellService.showLoadingIndicator(true);
    this.productTypes = [];
    this.productService.getProductTypes().subscribe({next:(response) => {
      if (response != null) {
        for (var i = 0; i < response.length; i++) {
          if (response[i].isVesselZone) {
            this.productTypes.push(response[i]);
          }
        }
        if (productType) {
          var rowIndex = this.productTypes.findIndex(x => x.name == productType);
          this.selectedProductType = this.productTypes[rowIndex];
          this.onProductTypeChange(null, productCategory, this.selectedProductType.id, product);
        }
      }
      this.shellService.showLoadingIndicator(false);
    }, error:(error) => {
      this.shellService.showLoadingIndicator(false);
  }})
  }

  onProductTypeChange(event: any, productCategory?: string, id?: number, product?: string) {
    var productTypeId = event == null ? 0 : event.value.id;
    if (productCategory) {
      productTypeId = id;
    }
    this.productService.getCategoryByProductType(productTypeId).subscribe(response => {
      if (response) {
        this.productCategories = response;
        if (productCategory) {
          var rowIndex = this.productCategories.findIndex(x => x.name == productCategory);
          this.selectedProductCategory = this.productCategories[rowIndex];
          this.ProductCategoryChange(null, product, this.selectedProductCategory.id);
        }
      }
    });
  }

  vesselProductCalcObj: any;
  vesselProductCost: any;
  vesselProductDialogControlChange(event: any, type: string) {
    if (type == 'product') {
      this.vesselProductCost = 0;
      this.vesselProductCost = event.value.costPerUnit;
    } else if (type == 'qty') {
      this.vesselProductObj.quantity = 0;
      this.vesselProductObj.quantity = event.value;
    }
    else if (type == 'labor') {
      this.vesselProductObj.labourhours = 0;
      this.vesselProductObj.labourhours = event.value;
    }
    else if (type == 'amountWorker') {
      this.vesselProductObj.amountWorker = 0;
      this.vesselProductObj.amountWorker = event.value;
    }
    this.calculateVesselProductCost();
  }

  resetVesselProuctDetail() {
    this.vesselProductObj = new VesselProductViewModel();
    this.vesselProductDialog.isProductTypeEmpty = false;
    this.vesselProductDialog.isProductCategoryEmpty = false;
    this.vesselProductDialog.isProductEmpty = false;
    this.vesselProductDialog.isQuantityEmpty = false;
    this.vesselProductDialog.isLabourHoursEmpty = false;
    this.vesselProductDialog.isAmountWorkerEmpty = false;
    this.vesselProductDialog.isUpdate = this.vesselProductDialog.isUpdate ? this.vesselProductDialog.isUpdate : false;
    this.selectedProductType = undefined;
    this.selectedProductCategory = undefined;
    this.selectedVesselProduct = undefined;
  }

  vesselProductObj: VesselProductViewModel = new VesselProductViewModel();

  vesselProductCols: any;
  vesselProductGridHeader() {
    this.vesselProductCols = [
      { field: 'productType', header: 'Product Type' },
      { field: 'productCategory', header: 'Product Category' },
      { field: 'product', header: 'Product' },
      { field: 'quantity', header: 'Quantity' },
      { field: 'labourhours', header: 'Labour hours' },
      { field: 'amountWorker', header: 'Amount of workers' },
      { field: 'cost', header: 'Cost' },
    ];
  }

  loadVesselProductGridData() {
    this.loading = true;
    this.vesselProducts = [];
    var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);
    var searchObj = {
      unitId: siteUnitOutage.unitId,
      outageId: siteUnitOutage.outageId,
      vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
      VesselEstimateRevisionId: this.selectedRevision ? this.selectedRevision.id : 0,
      active: true,
    }
    this.vesselProductService.loadVesselProduct(searchObj).subscribe(response => {
      if (response) {
        this.first = 0;
        this.vesselProducts = response;
      }
      this.loading = false;
    })
  }

  ProductCategoryChange(event: any, product?: string, id?: number) {
    var productCategoryId = event == null ? 0 : event.value.id;
    this.productDropList = [];
    if (product) {
      productCategoryId = id;
    }
    var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);
    this.productService.getProductByCategory(productCategoryId, siteUnitOutage.countryId).subscribe(response => {
      if (response) {
        this.productDropList = response;
        if (product) {
          var rowIndex = this.productDropList.findIndex(x => x.name == product);
          this.selectedVesselProduct = this.productDropList[rowIndex];
          this.vesselProductCost = this.selectedVesselProduct.costPerUnit;
        }
      }
    });
  }

  saveVesselProducts() {
    if (!this.validateVesselProduct()) {
      return;
    }
    var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);
    let vesselProductViewModel: any = {
      id: 0,
      siteUnitId: siteUnitOutage.unitId,
      outageId: siteUnitOutage.outageId,
      vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
      vesselEstimateRevisionId: this.selectedRevision ? this.selectedRevision.id : 0,
      productId: this.selectedVesselProduct.id,
      quantity: this.vesselProductObj.quantity,
      labourhours: this.vesselProductObj.labourhours,
      amountWorker: this.vesselProductObj.amountWorker,
      cost: this.vesselProductObj.cost,
      active: true,
      createdDate: new Date()
    }
    this.shellService.showLoadingIndicator(true);
    this.vesselProductService.addVesselProductDetails(vesselProductViewModel).subscribe({next:(response) => {
      this.shellService.showLoadingIndicator(false);
      this.vesselProductDialog.opened = false;
      // this.alertService.openAlert("Success!", "Saved successfully!", "success");
      this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Saved successfully!', severity: 'success' })
      this.loadVesselProductGridData();
      this.resetVesselProuctDetail();

    }, error:(error) => {
      this.shellService.showLoadingIndicator(false);

      this.vesselProductDialog.opened = false;
      this.resetVesselProuctDetail();
    }})
  }

  deleteVesselProductObj: any;
  deleteVesselProductRow(obj: any) {
    this.deleteObj = {};
    this.deleteVesselProductObj = {
      id: obj.id
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this Vessel Product?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'aq-destructive ',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        this.deleteVesselProductConfirm();
      },
      reject: () => {
        this.deleteVesselProductObj = {};
      }
    });
  }

  deleteVesselProductConfirm() {
    if (this.deleteVesselProductObj.id) {
      this.shellService.showLoadingIndicator(true);
      this.vesselProductService.deleteVesselProduct(this.deleteVesselProductObj.id).subscribe({
        next: (response) => {
          this.shellService.showLoadingIndicator(false);
          // this.alertService.openAlert("Deleted!", "Product deleted successfully!", "success");
          this.shellService.alert(this.dialogService, { title: 'Deleted!', message: 'Product deleted successfully!', severity: 'success' })
          this.loadVesselProductGridData();
          this.deleteVesselProductObj = {};
        }, error: () => {
          this.shellService.showLoadingIndicator(false);
        }
      })
    }
  }

  vesselProductId: number = 0;
  editVesselProduct(data: any) {
    this.vesselProductDialog.isUpdate = true;
    this.vesselProductId = data.id;

    this.vesselProductObj.active = data.active;
    this.vesselProductObj.quantity = data.quantity;
    this.vesselProductObj.labourhours = data.labourhours;
    this.vesselProductObj.amountWorker = data.amountWorker;
    this.vesselProductObj.cost = data.cost;
    this.getProductTypes(data.productType, data.productCategory, data.product);
    this.setVesselProductRowData(data.product, data.productCategory, data.productType);
    this.vesselProductDialog.opened = true;
  }

  //sets the original grid data
  vesselProductGridRowData: any;
  setVesselProductRowData(productName: string, productCategoryName: string, productType: string) {
    this.vesselProductGridRowData = {
      id: this.id,
      productName: productName,
      quantity: this.vesselProductObj.quantity,
      labourhours: this.vesselProductObj.labourhours,
      amountWorker: this.vesselProductObj.amountWorker,
      cost: this.vesselProductObj.cost
    }
  }

  //create array only for modified fields
  tempVesselProductPatchArrayObj: any = [];
  bindVesselProductPatchObject(vesselProduct: any) {
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (vesselProduct.productName != this.vesselProductGridRowData.productName && vesselProduct.productId > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("productId", vesselProduct.productId))
    }
    if (vesselProduct.quantity != this.vesselProductGridRowData.quantity) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("quantity", vesselProduct.quantity))
    }
    if (vesselProduct.labourhours != this.vesselProductGridRowData.labourhours) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("labourhours", vesselProduct.labourhours))
    }
    if (vesselProduct.amountWorker != this.vesselProductGridRowData.amountWorker) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("amountWorker", vesselProduct.amountWorker))
    }
    if (vesselProduct.cost != this.vesselProductGridRowData.cost) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("cost", vesselProduct.cost))
    }

    if (this.tempPatchArrayObj.length > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))

      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  updateVesselProduct() {
    if (!this.validateVesselProduct()) {
      return;
    }
    var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);

    let vesselProduct = {
      id: this.vesselProductId,
      siteUnitId: siteUnitOutage.unitId,
      outageId: siteUnitOutage.outageId,
      vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
      productId: this.selectedVesselProduct.id,
      productName: this.selectedVesselProduct.name,
      quantity: this.vesselProductObj.quantity,
      labourhours: this.vesselProductObj.labourhours,
      amountWorker: this.vesselProductObj.amountWorker,
      cost: this.vesselProductObj.cost,
      active: true,
    }
    var vesselProductViewModel = this.bindVesselProductPatchObject(vesselProduct);
    if (vesselProductViewModel.length > 0) {
      this.shellService.showLoadingIndicator(true);
      this.vesselProductService.updateVesselProduct(vesselProduct.id, vesselProductViewModel).subscribe({next:(response) => {
        this.shellService.showLoadingIndicator(false);
        this.loadVesselProductGridData();
        this.vesselProductDialog.opened = false;
        // this.alertService.openAlert("Success!", "Updated successfully!", "success");
        this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Updated successfully!', severity: 'success' })
        this.resetVesselProuctDetail();

      }, error:() => {
        this.shellService.showLoadingIndicator(false);
        this.vesselProductDialog.opened = false;
        this.resetVesselProuctDetail();
      }})
    }
  }

  //Cost = (Product Cost x Quantity) + (Amount of workers x Labour hours x gangrate)
  calculateVesselProductCost() {
    var productCost = this.vesselProductCost;
    var qty = this.vesselProductObj.quantity;
    var amountWorker = this.vesselProductObj.amountWorker;
    var labourhours = this.vesselProductObj.labourhours;
    var gangRate = this.gangRate;
    if (productCost > 0 && qty > 0 && amountWorker > 0 && labourhours > 0 && gangRate > 0) {
      var finalCalc = (productCost * qty) + (amountWorker * labourhours * gangRate);
      this.vesselProductObj.cost = finalCalc > 0 ? finalCalc : 0;
    }

  }

  addNewVesselProductRecordToggle(): void {
    if (this.addVesselProductNewRecordMode == true) {
      this.vesselProducts = [];
      this.isVesselAddNewMode = true;
    } else {
      this.isVesselAddNewMode = false;
    }

    var siteUnitId = this.shellService.loadGlobalSiteUnitOutage().unitId;
    var revisionId = this.selectedRevision ? this.selectedRevision.id : 0;
    //For adding new vessels we want to show vessels that has not been added yet, otherwise show
    //Vessels that has already been added for the revision. 
    this.getVesselProductEstimate(siteUnitId, revisionId, !this.addVesselProductNewRecordMode);
  }

  getVesselProductEstimate(siteUnitId: number, revisionId: number, forExistingVessels: boolean): void {
    this.selectedVessel = undefined;
    this.vessels = [];
    this.isVesselNameEmpty = true;
    this.refractoryEstimationService.getVesselBasedOnVesselProductEstimate(siteUnitId, revisionId, forExistingVessels).subscribe((response) => {
      if (response != null) {
        this.vessels = response;
      } else {
        this.vessels = [];
      }
    });
  }

  copyNominalVesselProduct() {
    this.addVesselProductMap(true);
  }

  copyNominalVesselProductDetail() {
    var siteUnitId = this.shellService.loadGlobalSiteUnitOutage().unitId;
    var outageId = this.shellService.loadGlobalSiteUnitOutage().outageId;
    var revisionId = this.selectedRevision ? this.selectedRevision.id : 0;
    var vesselId = this.selectedVessel ? this.selectedVessel.id : 0;

    var copyVesselProductViewModel = {
      unitId: siteUnitId,
      vesselId: vesselId,
      revisionId: revisionId,
      outageId: outageId
    }
    this.shellService.showLoadingIndicator(true);
    this.refractoryEstimationService.copyNominalVesselProduct(copyVesselProductViewModel).subscribe({next:(response) => {
      // this.alertService.openAlert("Success!", "Added successfully!", "success");
      this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Added successfully!', severity: 'success' })
      this.addNewVesselProductRecordToggle();
      this.shellService.showLoadingIndicator(false);
    }, error:() => {
      this.shellService.showLoadingIndicator(false);

    }})
  }

  checkWhetherEstimateRepairAreaModifiable(zoneId: number) {
    let details: { isLeafNode: boolean; id: number; } = this.zoneDetails.filter((x: any) => x.id == zoneId)[0];
    this.disableStatus = details['isLeafNode'] == true ? false : true;
    if (this.disableStatus)
      // this.alertService.openAlert("Read Only Zone", "Since this zone is not present at the lowest level of zone hierarchy, you will not be able to edit the Estimated Repair Area of this zone", "warning");
      this.shellService.alert(this.dialogService, { title: 'Read Only Zone', message: 'Since this zone is not present at the lowest level of zone hierarchy, you will not be able to edit the Estimated Repair Area of this zone', severity: 'warning' })
  }

  addVesselProductMap(isNominalCopy?: boolean) {
    var siteUnitId = this.shellService.loadGlobalSiteUnitOutage().unitId;
    var revisionId = this.selectedRevision ? this.selectedRevision.id : 0;
    var vesselId = this.selectedVessel ? this.selectedVessel.id : 0;

    if (siteUnitId > 0 && revisionId > 0 && vesselId > 0) {
      var vesselProductEstimateViewModel = {
        id: 0,
        siteUnitId: siteUnitId,
        vesselId: vesselId,
        vesselEstimateRevisionId: revisionId,
        active: true,
        createdDate: new Date()
      }
      this.shellService.showLoadingIndicator(true);
      this.refractoryEstimationService.addVesselProductEstimate(vesselProductEstimateViewModel).subscribe({next:(response) => {
        this.shellService.showLoadingIndicator(false);
        this.addVesselProductNewRecordMode = false;
        this.isVesselAddNewMode = false;
        if (isNominalCopy == true) {
          this.copyNominalVesselProductDetail();
        } else {
          // this.alertService.openAlert("Success!", "Saved successfully!", "success");
          this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Saved successfully!', severity: 'success' })
          this.addNewVesselProductRecordToggle();
        }

      }, error:() => {
        this.shellService.showLoadingIndicator(false);

      }})
    }

  }
}


export class ErmSearchParameters {
  unitId: number = 0;
  outageId: number = 0;
  vesselId: number = 0;
  zoneId: number = 0;
  productType: string = "";
  productPosition: string = "";
  revisionId: number = 0;
  active: boolean = false;
}