import { DecimalPipe, NgClass, NgFor, NgIf, NgStyle } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ConfirmationService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { DialogService } from "primeng/dynamicdialog";
import { InputNumberModule } from "primeng/inputnumber";
import { InputSwitchModule } from "primeng/inputswitch";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { TableModule } from "primeng/table";
import { ProductTypeCode } from "../../../shared/constants";
import { ShellService } from "../../../shared/services/shell/shell.service";
import { CategoryType } from "../models/categoryType.model";
import { Country } from "../models/country.model";
import { Category } from "../models/productCategory.model";
import { VendorDropdown } from "../models/vendor.model";
import { CountryService } from "../services/country.service";
import { ProductService } from "../services/product/product.service";
import { AccessLevel } from "../../../auth/app-roles";


@Component({
  selector: 'app-product-information',
  standalone: true,
  imports: [NgIf,NgFor,NgClass,NgStyle,TableModule,ButtonModule,DropdownModule,InputNumberModule,InputSwitchModule,DialogModule,FormsModule,DecimalPipe,ConfirmDialogModule,OverlayPanelModule],
  providers:[ConfirmationService,DialogService],
  templateUrl: './product-information.component.html',
  styleUrl: './product-information.component.scss'
})
export class ProductInformationComponent implements OnInit {
  siteUnitOutageObj: any;
  // Filter
  isActive = true
  IsBtnUpdateDisabled: boolean=false;
  isFilterModeOn = false
  filterCategoryTypeList: CategoryType[]=[];
  filterCategoryList: any = [];
  filterVendorList: any = [];
  selectedFilterCountry: any;
  filterCountries: Country[]=[];
  filterCategoryType: any;
  filterProduct: string = "";
  filterCategory: any;
  filterVendor: any;
  productCols: any;
  productCostCols: any;
  productGridData: any = [];
  productGridData1: any;
  loading: boolean = false;
  first: number = 0;

  //Save/Update
  id: number=0;
  productName: string = "";
  description: string = "";
  stockNumber: number=0;
  publishedDensity: number=0;
  productCategoryList: Category[]=[];
  selectedCategory: any;
  vendorList: VendorDropdown[]=[];
  selectedVendor: any;

  //modal
  countries: Country[]=[];
  selectedCountry: any;
  cost: number=0;

  accessLevel: AccessLevel=AccessLevel.NoAccess

  constructor(private productService: ProductService,
    private shellService: ShellService,
    private dialogService:DialogService,
    private confirmationService: ConfirmationService, private countryService: CountryService) { }

  ngOnInit(): void {
    // this.shellService.getAccessLevel().then((accessLevel) => {
    //   this.accessLevel = accessLevel;
    // })
    this.accessLevel=this.shellService.getAppAccessLevel();
    // Retrieve the object from localstorage
    this.siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage(false, false);
    this.getProductCategoryTypes();
    this.getVendors();
    this.getCountries();
    this.productCols = [
      { field: 'name', header: 'Name' },
      { field: 'vendorName', header: 'Vendor' },
      { field: 'productCategoryName', header: 'Category' },
      { field: 'description', header: 'Description' },
      { field: 'stockNumber', header: 'Stock No.' },
      { field: 'publishedDensity', header: 'Density' },
    ];
    this.productCostCols = [
      { field: 'countryName', header: 'Country' },
      { field: 'cost', header: 'Cost', format: true },
    ]

  }


  getProductCategoryTypes(): void {
    this.shellService.showLoadingIndicator(true);
    this.filterCategoryTypeList = [];
    this.productService.getProductTypes().subscribe((response) => {
      this.filterCategoryTypeList = response;
      this.shellService.showLoadingIndicator(false);
    }, (error) => {
      this.shellService.showLoadingIndicator(false);
    })
  }

  getVendors() {
    this.productService.getVendor().subscribe((response) => {
      this.filterVendorList = response;
    });
  }

  getCountries() {
    this.countryService.getCountries().subscribe((response) => {
      this.filterCountries = response;
      this.countries = response;
    });
  }

  onProductCategoryTypeChange(event: any) {
    var productTypeId = event.value.id;
    this.productService.getCategoryByProductType(productTypeId).subscribe(response => {
      if (response) {
        this.filterCategoryList = response;
        this.filterProduct = "";
        this.filterCategory = undefined;
      }
    });
  }

  // Filter
  showFilter(): void {
    this.isFilterModeOn = true
  }

  closeFilter(): void {
    this.isFilterModeOn = false
  }

  filterRecords(): void {
    this.productGridData = [];
    let filterProductObj = {
      productTypeId: this.filterCategoryType ? this.filterCategoryType.id : 0,
      productCategoryId: this.filterCategory ? this.filterCategory.id : 0,
      vendorId: this.filterVendor ? this.filterVendor.id : 0,
      countryId: this.selectedFilterCountry ? this.selectedFilterCountry.id : 0,
      name: this.filterProduct,
      active: this.isActive
    }
    this.loading = true;
    this.first = 0;
    this.productService.searchProducts(filterProductObj).subscribe(response => {
      this.loading = false;
      if (response) {
        this.bindProductGridData(response);
      }
    });
  }

  bindProductGridData(response: any) {
    this.productGridData = [];
    var productName = "default";
    for (var i = 0; i < response.length; i++) {
      var pName = response[i].name;
      if (pName != productName) {
        this.productGridData.push({
          id: response[i].id,
          name: response[i].name,
          description: response[i].description,
          productTypeId: response[i].productTypeId,
          productCategoryId: response[i].productCategoryId,
          productCategoryName: response[i].productCategoryName,
          vendorId: response[i].vendorId,
          stockNumber: response[i].stockNumber,
          publishedDensity: response[i].publishedDensity,
          vendorName: response[i].vendorName,
          active: response[i].active,
          productCosts: [],
          productCategoryActive: response[i].productCategoryActive,
          vendorActive: response[i].vendorActive,
        })
        for (var j = 0; j < response.length; j++) {
          if (response[j].name == pName) {
            productName = pName;
            if (response[j].productCostId) {
              this.productGridData[this.productGridData.length - 1].productCosts.push({
                id: response[j].productCostId,
                productId: response[j].id,
                cost: response[j].costPerUnit,
                countryId: response[j].countryId,
                countryName: response[j].countryName,
                active: response[j].productCostActive
              })
            }
          }
        }
        // console.log(this.productGridData, 'pg');
      } else {
        for (var k = 0; k < response.length; k++) {
          if (response[k].name === pName) {
            response.splice(k, 1);
            i--;
          }
        }
      }
    }
  }

  resetFilterControls(): void {
    this.filterCategoryType = undefined;
    this.filterProduct = "";
    this.filterCategory = undefined;
    this.filterVendor = undefined;
    this.selectedFilterCountry = undefined;
    this.isActive = true;
    this.productGridData = [];
    this.first = 0;
  }

  //#region save/update product informaation
  productDialog = {
    opened: false,
    isUpdate: false,
    productType: '',
    isNameEmpty: false,
    isCategoryEmpty: false,
    isVendorEmpty: false,
    isDensityEmpty: false,
    productTypeId: 0
  }

  toggleProductDialog(productTypeId: number, title: string) {
    this.resetProductDialog();
    if (productTypeId == ProductTypeCode.Anchor) { //Anchor code is 1
      this.productDialog.productType = "Anchor";
    } else if (productTypeId == ProductTypeCode.Refractory) { //Refractory code is 2
      this.productDialog.productType = "Refractory";
    } else {
      this.productDialog.productType = title;
    }
    this.loadProductDialogDropdown(productTypeId);
    this.productDialog.productTypeId = productTypeId;
    this.productDialog.opened = !this.productDialog.opened;
  }

  resetProductDialog() {
    this.id = 0;
    this.productName = "";
    this.description = "";
    this.stockNumber = 0;
    this.publishedDensity = 0;
    this.selectedCategory = undefined;
    this.selectedVendor = undefined;
    this.productDialog.isUpdate = false;
    this.productDialog.isNameEmpty = false;
    this.productDialog.isCategoryEmpty = false;
    this.productDialog.isVendorEmpty = false;
    this.productDialog.isDensityEmpty = false;
    this.productDialog.productTypeId = 0;
  }

  loadProductDialogDropdown(productTypeId: number, isEditUpdate?: boolean, category?: string, vendorName?: string) {
    var productTypeId = productTypeId;
    this.productService.getCategoryAndVendor(productTypeId).subscribe(response => {
      if (response) {
        this.productCategoryList = response[0];
        this.vendorList = response[1];
        if (isEditUpdate == true) {
          this.selectedCategory = this.productCategoryList.filter(c => c.name == category)[0];
          this.selectedVendor = this.vendorList.filter(c => c.name == vendorName)[0];
        }
      }
    });
  }

  validateProductDialog(): boolean {
    var isValid = true;
    let category = this.selectedCategory ? this.selectedCategory.name : "";
    if (category || !this.gridRowData.productCategoryActive) {
      this.productDialog.isCategoryEmpty = false;
    } else {
      isValid = false;
      this.productDialog.isCategoryEmpty = true;
    }

    if (this.productName) {
      var name = this.productName.trim();
      if (name || !this.gridRowData.active) {
        this.productDialog.isNameEmpty = false;
      } else {
        this.productDialog.isNameEmpty = true;
        isValid = false;
      }
    } else {
      this.productDialog.isNameEmpty = true;
      isValid = false;
    }

    let vendor = this.selectedVendor ? this.selectedVendor.name : "";
    if (vendor || !this.gridRowData.vendorActive) {
      this.productDialog.isVendorEmpty = false;
    } else {
      isValid = false;
      this.productDialog.isVendorEmpty = true;
    }

    if (this.productDialog.productType == "Refractory") {
      if (this.publishedDensity > 0) {
        this.productDialog.isDensityEmpty = false;
      } else {
        isValid = false;
        this.productDialog.isDensityEmpty = true;
      }
    }
    return isValid;
  }

  addProductDetails() {
    if (!this.validateProductDialog()) {
      return;
    }
    let productObj = {
      id: 0,
      name: this.productName,
      description: this.description,
      productCategoryId: this.selectedCategory ? this.selectedCategory.id : 0,
      VendorId: this.selectedVendor ? this.selectedVendor.id : 0,
      stockNumber: this.stockNumber,
      publishedDensity: this.publishedDensity,
      active: true,
      createdDate: new Date()
    }
    this.productService.addProduct(productObj).subscribe(response => {
      this.productDialog.opened = false;
      // this.alertService.openAlert("Success!", "Saved Successfully!", "success");
      this.shellService.alert(this.dialogService,{title:'Success!',message:'Saved Successfully!',severity:'success'})
      var productTypeId = this.productDialog.productTypeId;
      this.filterCategoryType = this.filterCategoryTypeList.filter(x => x.id == productTypeId)[0];
      this.filterRecords();
    }, error => {
      this.productDialog.opened = false;
      // this.alertService.openAlert("Error!", error, "error");
      this.shellService.alert(this.dialogService,{title:'Success!',message:error,severity:'error'})
    })
  }

  updateProduct() {
    if (!this.validateProductDialog()) {
      return;
    }
    let productObj = {
      id: this.id,
      name: this.productName,
      description: this.description,
      productCategoryId: this.selectedCategory ? this.selectedCategory.id : 0,
      vendorId: this.selectedVendor ? this.selectedVendor.id : 0,
      stockNumber: this.stockNumber,
      publishedDensity: this.publishedDensity,
    }
    var productViewModel = this.bindProductPatchObject(productObj);
    if (productViewModel.length > 0) {
      this.productService.updateProduct(this.id, productViewModel).subscribe(response => {
        this.productDialog.opened = false;
        // this.alertService.openAlert("Success!", "Updated Successfully!", "success");
        this.shellService.alert(this.dialogService,{title:'Success!',message:'Updated Successfully!',severity:'success'})
        this.filterRecords();
      }, (error) => {
        this.productDialog.opened = false;
        // this.alertService.openAlert("Error!", error, "error");
        this.filterRecords();
      })
    }
  }
  //#endregion

  editRow(data: any) {
    this.id = data.id;
    this.productName = data.name;
    this.description = data.description;
    this.stockNumber = data.stockNumber;
    this.publishedDensity = data.publishedDensity;
    this.loadProductDialogDropdown(data.productTypeId, true, data.productCategoryName, data.vendorName);
    if (data.productTypeId == ProductTypeCode.Anchor) { //Anchor code is 1
      this.productDialog.productType = "Anchor";
    } else if (data.productTypeId == ProductTypeCode.Refractory) { //Refractory code is 2
      this.productDialog.productType = "Refractory";
    }
    this.productDialog.isUpdate = true;
    this.productDialog.opened = true;
    this.setRowData(data, data.productCategoryId, data.vendorId);
  }

  gridRowData: any;
  setRowData(data: any, categoryId: string, vendorId?: string) {
    this.gridRowData = {
      id: data.id,
      name: data.name,
      description: data.description,
      productCategoryId: categoryId,
      vendorId: vendorId,
      stockNumber: data.stockNumber,
      productCategoryName: data.productCategoryName,
      vendorName: data.vendorName,
      active: data.active,
      productCategoryActive: data.productCategoryActive,
      vendorActive: data.vendorActive
    }
  }

  //create array only for modified fields
  tempPatchArrayObj: any = [];
  bindProductPatchObject(productObj: any) {
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (productObj.id != this.gridRowData.id) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("id", productObj.id))
    }
    if (productObj.name != this.gridRowData.name) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("name", productObj.name))
    }
    if (productObj.productCategoryId != this.gridRowData.productCategoryId && this.gridRowData.productCategoryActive) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("ProductCategoryId", productObj.productCategoryId))
    }
    if (productObj.vendorId != this.gridRowData.vendorId && this.gridRowData.vendorActive) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("vendorId", productObj.vendorId))
    }
    if (productObj.stockNumber != this.gridRowData.stockNumber) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("stockNumber", productObj.stockNumber))
    }
    if (productObj.description != this.gridRowData.description) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("description", productObj.description))
    }
    if (this.productDialog.productType == "Refractory") {
      if (productObj.publishedDensity != this.gridRowData.publishedDensity) {
        this.tempPatchArrayObj.push(this.shellService.createPatchArray("publishedDensity", productObj.publishedDensity))
      }
    }

    if (this.tempPatchArrayObj.length > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))
      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  deleteRow(data: any) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this product?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'aq-destructive',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        if (data.id) {
          this.shellService.showLoadingIndicator(true);
          this.productService.deleteProduct(data.id).subscribe(response => {
            // this.alertService.openAlert("Success!", "Deleted Successfully!", "success");
            this.shellService.alert(this.dialogService,{title:'Success!',message:'Deleted Successfully!',severity:'success'})
            this.shellService.showLoadingIndicator(false);
            this.filterRecords();
          }, error => {
            // this.alertService.openAlert("Error!", error, "error");
            this.shellService.alert(this.dialogService,{title:'Error!',message:error,severity:'error'})
          })
        }
      }
    });
  }

  reactivateProductRow(obj: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reactivate this product?',
      acceptLabel: 'Activate',
      acceptIcon: 'pi pi-chevron-circle-up',
      acceptButtonStyleClass: 'aq-primary ',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        var tempPatchArrayObj = [];
        var patchArrayObj = [];
        tempPatchArrayObj.push(this.shellService.createPatchArray("active", true))
        tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))

        for (var i = 0; i < tempPatchArrayObj.length; i++) {   //pushing the 0th
          patchArrayObj.push(tempPatchArrayObj[i][0]);
        }
        var productViewModel = patchArrayObj;
        if (productViewModel.length > 0 && obj.id) {
          this.shellService.showLoadingIndicator(true);
          this.productService.updateProduct(obj.id, productViewModel).subscribe(response => {
            // this.alertService.openAlert("Success!", "Updated Successfully!", "success");
            this.shellService.alert(this.dialogService,{title:'Succcess!',message:'Updated SUccessfully!',severity:'success'})
            this.shellService.showLoadingIndicator(false);
            this.filterRecords();
          }, (error) => {
            this.shellService.showLoadingIndicator(false);
            // this.alertService.openAlert("Error!", error, "error");
            this.shellService.alert(this.dialogService,{title:'Error!',message:error,severity:'error'})
            this.filterRecords();
          })
        }
      }
    });
  }

  //Product cost
  costDialog = {
    opened: false,
    isUpdate: false
  }

  resetCostDialog() {
    this.selectedCountry = undefined;
    this.cost = 0;
    this.costDialog.isUpdate = false;
  }

  productCostId: number | any;
  toggleCostDialog(data: any, type: string) {
    this.resetCostDialog();
    if (type == 'add') {
      this.productCostId = data.id;
      this.costDialog.isUpdate = false;
    } else if (type == 'update') {
      this.costDialog.isUpdate = true;
      this.productCostId = data.id;
      this.cost = data.cost;
      if (data.countryId) {
        this.selectedCountry = this.countries.filter(c => c.name == data.countryName)[0];
      }
    }
    this.costDialog.opened = !this.costDialog.opened;
  }

  addProductCost() {
    let productCostObj = {
      id: 0,
      productId: this.productCostId,
      countryId: this.selectedCountry ? this.selectedCountry.id : 0,
      cost: this.cost,
      active: true,
      createdDate: new Date()
    }
    this.productService.addProductCost(productCostObj).subscribe(response => {
      this.costDialog.opened = false;
      // this.alertService.openAlert("Success!", "Saved Successfully!", "success");
      this.shellService.alert(this.dialogService,{title:'Success!',message:'Saved Successfully!',severity:'success'})
      this.filterRecords();
    }, error => {
      this.costDialog.opened = false;
      // this.alertService.openAlert("Error!", error, "error");
      this.shellService.alert(this.dialogService,{title:'Error!',message:error,severity:'error'})
    })
  }

  updateProductCost() {
    this.shellService.showLoadingIndicator(true);
    this.IsBtnUpdateDisabled = true;
    var tempPatchArrayObj = [];
    var patchArrayObj = [];
    var countryId = this.selectedCountry ? this.selectedCountry.id : 0
    tempPatchArrayObj.push(this.shellService.createPatchArray("cost", this.cost))
    tempPatchArrayObj.push(this.shellService.createPatchArray("countryId", countryId))
    tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))

    for (var i = 0; i < tempPatchArrayObj.length; i++) {   //pushing the 0th
      patchArrayObj.push(tempPatchArrayObj[i][0]);
    }

    var productCostViewModel = patchArrayObj;
    this.productService.updateProductCost(this.productCostId, productCostViewModel).subscribe(response => {
      this.IsBtnUpdateDisabled = false;
      this.costDialog.opened = false;
      this.shellService.showLoadingIndicator(false);
      // this.alertService.openAlert("Success!", "Product Cost Updated Successfully!", "success");
      this.shellService.alert(this.dialogService,{title:'Success',message:'Product Cost updated successfully',severity:'success'})
      this.filterRecords();
    }, error => {
      this.IsBtnUpdateDisabled = false;
      this.costDialog.opened = false;
      this.shellService.showLoadingIndicator(false);
      // this.alertService.openAlert("Error!", error, "error");
      this.shellService.alert(this.dialogService,{title:'Error!',message:error,severity:'error'})
    })
  }

  deleteProductCostRow(data: any) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete this product cost?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'aq-destructive ',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'p-button-outlined p-buttom-sm',
      accept: () => {
        var tempPatchArrayObj = [];
        var patchArrayObj = [];
        tempPatchArrayObj.push(this.shellService.createPatchArray("active", false))
        tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))

        for (var i = 0; i < tempPatchArrayObj.length; i++) {   //pushing the 0th
          patchArrayObj.push(tempPatchArrayObj[i][0]);
        }
        var productCostViewModel = patchArrayObj;
        if (productCostViewModel.length > 0 && data.id) {
          this.shellService.showLoadingIndicator(true);
          this.productService.updateProductCost(data.id, productCostViewModel).subscribe(response => {
            // this.alertService.openAlert("Success!", "Deleted Successfully!", "success");
            this.shellService.alert(this.dialogService,{title:'Success!',message:'Deleted Successfully',severity:'success'})
            this.shellService.showLoadingIndicator(false);
            this.filterRecords();
          }, error => {
            // this.alertService.openAlert("Error!", error, "error");
            this.shellService.alert(this.dialogService,{title:'Error!',message:error,severity:'error'})
          })
        }
      }
    });
  }

  reactivateRow(obj: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reactivate this product cost?',
      acceptLabel: 'Activate',
      acceptIcon: 'pi pi-chevron-circle-up',
      acceptButtonStyleClass: 'aq-success ',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        var tempPatchArrayObj = [];
        var patchArrayObj = [];
        tempPatchArrayObj.push(this.shellService.createPatchArray("active", true))
        tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))

        for (var i = 0; i < tempPatchArrayObj.length; i++) {   //pushing the 0th
          patchArrayObj.push(tempPatchArrayObj[i][0]);
        }
        var productCostViewModel = patchArrayObj;
        if (productCostViewModel.length > 0 && obj.id) {
          this.shellService.showLoadingIndicator(true);
          this.productService.updateProductCost(obj.id, productCostViewModel).subscribe(response => {
            // this.alertService.openAlert("Success!", "Product cost activated successfully!", "success");
            this.shellService.alert(this.dialogService,{title:'Success!',message:'Product cost activated successfully!',severity:'success'})
            this.shellService.showLoadingIndicator(false);
            this.filterRecords();
          }, error => {
            // this.alertService.openAlert("Error!", error, "error");
            this.shellService.alert(this.dialogService,{title:'Error!',message:error,severity:'error'})
          })
        }
      }
    });
  }
}
