import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, forkJoin, Observable } from 'rxjs';
import { ErmSearchParameters } from '../../estimate-refractory-materials/estimate-refractory-materials.component';
import { AnchorRefractoryViewModel } from '../../models/nominalLining.model';
import { Vessel } from '../../models/vessel.model';
import { AppHttpHeaders } from '../../../../shared/services/common/app-http-headers';
import { ShellService } from '../../../../shared/services/shell/shell.service';

@Injectable({
  providedIn: 'root'
})
export class RefractoryEstimationService {
  appReqHeader = new AppHttpHeaders();
  URL = this.appReqHeader.domainURL;
  refractoryEstimateURL = this.appReqHeader.domainURL + "api/EstimateRefractoryMaterial";
  nominalLiningUrl = this.appReqHeader.domainURL + 'api/NominalLining';
  zoneUrl = this.appReqHeader.domainURL + 'api/Zone';

  constructor(private http: HttpClient, private shellService: ShellService) { }

  getVesselBasedOnVesselEstimate(siteUnitId: number, revisionId: number) {
    var queryParams = "";
    if (siteUnitId != undefined) {
      queryParams = "/" + siteUnitId;
    }
    if (revisionId != undefined) {
      queryParams = queryParams + "/" + revisionId;
    }
    return this.http.get<Vessel[]>(this.URL + "api/VesselEstimate" + queryParams,
    { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  //forExistingVessel true for fetching vessels that has already been added to the revision, otherwise false to get
  //vessels that can be added to the revision
  getVesselBasedOnVesselProductEstimate(siteUnitId: number, revisionId: number, forExistingVessels:boolean) {
    var queryParams = "";
    if (siteUnitId != undefined) {
      queryParams = "/" + siteUnitId;
    }
    if (revisionId != undefined) {
      queryParams = queryParams + "/" + revisionId;
    }
    if (forExistingVessels != undefined) {
      queryParams = queryParams + "/" + forExistingVessels;
    }

    return this.http.get<Vessel[]>(this.URL + "api/VesselProduct" + queryParams,
      { headers: this.appReqHeader.formHeaders }).pipe(
        catchError(err => this.shellService.handleError(err))
      );
  }

  getZoneBasedOnVesselEstimate(siteUnitId: number, revisionId: number, vesselId: number, addNewRecordMode: boolean) {
    var queryParams = "";
    if (siteUnitId != undefined) {
      queryParams = "/" + siteUnitId;
    }
    if (revisionId != undefined) {
      queryParams = queryParams + "/" + revisionId+"/Vessel";
    }
    if (revisionId != undefined) {
      queryParams = queryParams + "/" + vesselId + "/Zones/" + addNewRecordMode;
    }

    return this.http.get<any>(this.URL + "api/VesselEstimate" + queryParams,
      { headers: this.appReqHeader.formHeaders }).pipe(
        catchError(err => this.shellService.handleError(err))
      );
  }

  public getRefractoryEstimateAllProducts(anchorBackupObj: ErmSearchParameters,
    anchorHotFaceObj: ErmSearchParameters, refractoryBackupObj: ErmSearchParameters,
    refractoryHotfaceObj: ErmSearchParameters, obj: ErmSearchParameters): Observable<any[]> {
    var queryParams = "";
    if (obj.unitId != undefined) {
      queryParams = "/" + obj.unitId;
    }
    if (obj.vesselId != undefined) {
      queryParams = queryParams + "/" + obj.vesselId;
    }
    if (obj.zoneId != undefined) {
      queryParams = queryParams + "/" + obj.zoneId;
    }
   
    let estimateAnchorBackup = this.http.post<any>(this.refractoryEstimateURL +
      '/SearchEstimateProductAnchorBackup', 
      anchorBackupObj, 
      { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));
    let estimateAnchorHotface = this.http.post<any>(this.refractoryEstimateURL +
      '/SearchEstimateProductAnchorHotface', 
      anchorHotFaceObj, 
      { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));
    let estimateRefractoryBackup = this.http.post<any>(this.refractoryEstimateURL + 
      '/SearchEstimateProductRefractoryAnchor', 
      refractoryBackupObj, 
      { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));
    let estimateRefractoryHotface = this.http.post<any>(this.refractoryEstimateURL + 
        '/SearchEstimateProductRefractoryHotface', 
        refractoryHotfaceObj, 
        { headers: this.appReqHeader.formHeaders }).pipe(
        catchError(err => this.shellService.handleError(err)));
    let zoneResponse = this.http.get<any>(this.zoneUrl + queryParams + "/VesselZoneCalculationFactors", { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));
    let refractoryDetailsResponse = this.http.get<any>(this.URL + "api/RefractoryDetail" + queryParams, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));

    if (obj.revisionId != undefined) {
      queryParams = queryParams + "/" + obj.revisionId;
    }
    let repairAreaResponse = this.http.get<any>(this.refractoryEstimateURL + queryParams  + "/VesselEstimate", { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));

    return forkJoin([estimateAnchorBackup, 
                     estimateAnchorHotface, 
                     estimateRefractoryBackup, 
                     estimateRefractoryHotface,
                     repairAreaResponse,
                     zoneResponse,
                     refractoryDetailsResponse]);
  }


  loadAnchorBackup(anchorBackupObj: any) {
    return this.http.post<any>(this.refractoryEstimateURL + '/SearchEstimateProductAnchorBackup', anchorBackupObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));
  }

  loadAnchorHotface(anchorBackupObj: any) {
    return this.http.post<any>(this.refractoryEstimateURL + '/SearchEstimateProductAnchorHotface', anchorBackupObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));
  }

  loadRefractoryBackup(refractoryBackupObj: any) {
    return this.http.post<any>(this.refractoryEstimateURL + '/SearchEstimateProductRefractoryAnchor', refractoryBackupObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));
  }

  loadRefractoryHotface(refractoryBackupObj: any) {
    return this.http.post<any>(this.refractoryEstimateURL + '/SearchEstimateProductRefractoryHotface', refractoryBackupObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));
  }

  addAnchorRefractoryDetails(anchorRefractoryViewModel: AnchorRefractoryViewModel) {
    return this.http.post<any>(this.refractoryEstimateURL + '/Add', anchorRefractoryViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  saveRefractoryDetails(gridData: any, vesselEstimateId: number, repairArea: number) {
    return this.http.post<any>(this.refractoryEstimateURL + '/' +
                              vesselEstimateId.toString() + '/' + repairArea.toString(),
                              gridData, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateAnchorRefractoryDetails(Id: number, patchNominalViewModel: any) {
    var query = "";
    if (Id) {
      query = "/" + Id;
    }
    return this.http.patch<any>(this.refractoryEstimateURL + query, patchNominalViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deleteAnchorRefractoryDetails(id: number) {
    var query = "";
    if (id) {
      query = "/" + id;
    }
    return this.http.delete<any>(this.refractoryEstimateURL + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  bulkDeleteEstimateRefractoryDetails(unitId: number,
                                      vesselId: number,
                                      zoneId: number,
                                      outageId: number,
                                      revisionId: number) {
    var queryParams = "";
    if (unitId != undefined) {
      queryParams = "/" + unitId;
    }
    if (vesselId != undefined) {
      queryParams = queryParams + "/" + vesselId;
    }
    if (zoneId != undefined) {
      queryParams = queryParams + "/" + zoneId;
    }
    if (outageId != undefined) {
      queryParams = queryParams + "/" + outageId;
    }
    if (revisionId != undefined) {
      queryParams = queryParams + "/" + revisionId;
    }

    return this.http.delete<any>(this.refractoryEstimateURL + queryParams , { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getVesselEstimate(obj:ErmSearchParameters) {
    var queryParams = "";
    if (obj.unitId != undefined) {
      queryParams = "/" + obj.unitId;
    }
    if (obj.vesselId != undefined) {
      queryParams = queryParams + "/" + obj.vesselId;
    }
    if (obj.zoneId != undefined) {
      queryParams = queryParams + "/" + obj.zoneId;
    }
    if (obj.revisionId != undefined) {
      queryParams = queryParams + "/" + obj.revisionId;
    }

    return this.http.get<any>(this.refractoryEstimateURL + queryParams + "/VesselEstimate", { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));
  }

  saveVesselEstimate(vesselEstimateObject: any) {
    return this.http.post<any>(this.URL + "api/VesselEstimate", vesselEstimateObject, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  addVesselProductEstimate(vesselProductEstimateViewModel: any) {
    return this.http.post<any>(this.URL + "api/VesselProduct" + "/addVesselProductEstimate", vesselProductEstimateViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
  
  copyNominalVesselProduct(copyVesselProductViewModel: any) {
    return this.http.post<any>(this.URL + "api/VesselProduct" + "/CopyNominlLiningVesselProduct", copyVesselProductViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateVesselEstimate(vesselEstimatePatchObject: any, id: number) {
    var query = "";
    if (id) {
      query = "/" + id;
    }
    return this.http.patch<any>(this.URL + "api/VesselEstimate" + query, vesselEstimatePatchObject, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  addRevision(revisionObject: any) {
    return this.http.post<any>(this.URL + "api/VesselEstimateRevision/CreateNewRevision", revisionObject, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
   }
   
  /**
   * This method is used to create a revision from a template
   * @param revisionObject {}
   * @param overwrite number
   * @param previousRevisionNumber number 
   * @returns 
   */
   addRevisionFromTempate(revisionObject:any,overwrite:number,previousRevisionNumber:number){
    let subURL=`api/VesselEstimateRevision/CreateNewRevisionFromTemplate/${overwrite}/${previousRevisionNumber}`
    let fullURL=this.URL+subURL;

    return this.http.post<any>(fullURL,revisionObject, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );

   }
  
   updateVesselEstimateRevision(vesselEstimateRevisionPatchObject: any, id: number) {
    var query = "";
    if (id) {
      query = "/" + id;
    }
    return this.http.patch<any>(this.URL + "api/VesselEstimateRevision" + query, vesselEstimateRevisionPatchObject, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
